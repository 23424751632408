//************************  React import   ************************ //
import React, { useEffect, useRef, useState } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../../components";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//************************ Constant Data import   ************************ //
import { CONTACT } from "../../../data/apis";

//************************  Other Library import   ************************ //
// props validation
import PropTypes from "prop-types";

// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

/////////////////////////////////////////////////////////////////////////////

const Contact = () => {
  //************************  Hooks   ************************ //
  const [formCloneWidth, setFormCloneWidth] = useState(0);
  const [formCloneHeight, setFormCloneHeight] = useState(0);

  const sm = useMediaQuery("(max-width: 560px)");

  const formRef = useRef(null);

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const contactUsLang = useSelector((state) => state.lang.value.home.contactUs);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  const [request, data] = useFetch({ url: CONTACT, method: "post" });

  //*************************************************************** //

  //************************  useEffect ************************ //
  useEffect(() => {
    setFormCloneWidth(formRef.current.getBoundingClientRect().width);
    setFormCloneHeight(formRef.current.getBoundingClientRect().height);
  }, []);

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // Form Validation Schema using Yup library
  const contactSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const { handleSubmit, handleChange, values, touched, errors, handleReset } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        service: "",
        description: "",
      },

      validationSchema: contactSchema,
      onSubmit: () => {
        request({
          onSuccess: (res) => {
            handleReset();
          },
          body: values,
          onFail: (err) => {
            console.log(err.response.data);
          },
        });
      },
    });
  //*************************************************************** //

  return (
    <Box
      className="global-padding-y"
      id="contactUs"
      sx={{
        backgroundImage: "linear-gradient(136deg, #EF543599, #F78A24B3)",
      }}
    >
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 7,
          }}
        >
          <Heading>{contactUsLang.header[lang]}</Heading>
          <Box
            sx={{
              width: sm ? "100%" : 400,
              position: "relative",
            }}
          >
            {!sm && (
              <Box
                sx={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #EF543599, #F78A24B3)",
                  borderRadius: "0 30px 0 30px",
                  width: `${formCloneWidth}px`,
                  height: `${formCloneHeight}px`,
                  position: "absolute",
                  transform: "translate(-40px, -5px) rotate(8deg)",
                  zIndex: -1,
                }}
              ></Box>
            )}
            <Box
              component={"form"}
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                bgcolor: sm ? "transparent" : "white",
                p: 5,
                borderRadius: "0 30px 0 30px",
                boxShadow: sm ? "unset" : "0 0 20px #f78a2482",
              }}
              ref={formRef}
            >
              <ContactField>
                <TextField
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  placeholder={contactUsLang.name[lang]}
                />
              </ContactField>
              <ContactField>
                <TextField
                  placeholder={contactUsLang.email[lang]}
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                />
              </ContactField>

              {/* <ContactField>
                <TextField
                  id="service"
                  name="service"
                  value={values.service}
                  onChange={handleChange}
                  error={touched.service && Boolean(errors.service)}
                  select
                  placeholder={contactUsLang.service[lang]}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!Boolean(selected)) {
                        return (
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.neutral.main,
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                              opacity: 0.5,
                            }}
                          >
                            {contactUsLang.service[lang]}
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.neutral.main,
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                            }}
                          >
                            {selected}
                          </Typography>
                        );
                      }
                    },
                    IconComponent: KeyboardArrowDownIcon,
                    sx: {
                      "& .MuiSvgIcon-fontSizeMedium": { fontSize: "2.5rem" },
                    },
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root": {
                            color: (theme) => theme.palette.neutral.main,
                            fontWeight: "bold",
                            fontSize: "1.3rem",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">...</MenuItem>
                  <MenuItem value="خدمة 1">خدمة 1</MenuItem>
                  <MenuItem value="خدمة 2">خدمة 2</MenuItem>
                  <MenuItem value="خدمة 3">خدمة 3</MenuItem>
                </TextField>
              </ContactField> */}

              <ContactField>
                <TextField
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  placeholder={contactUsLang.question[lang]}
                  multiline
                  rows={4}
                />
              </ContactField>
              <Button sx={{ width: 150 }} type="submit">
                {global.send[lang]}
              </Button>
            </Box>
          </Box>
          {data.successAlert}
          {data.failAlert}
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Contact;

const ContactField = ({ children }) => {
  return (
    <Box
      sx={{
        border: "1px solid #F78A24",
        borderRadius: "0 15px 0 15px",
        width: "100%",
        bgcolor: "white",
      }}
    >
      {React.cloneElement(children, {
        sx: {
          width: "100%",
          height: "100%",
          "& input": {
            color: (theme) => theme.palette.neutral.main,
            fontWeight: "bold",
            fontSize: "1.3rem",
          },
          "& textarea": {
            color: (theme) => theme.palette.neutral.main,
            fontWeight: "bold",
            fontSize: "1.3rem",
          },
          "& input::placeholder": {
            color: (theme) => theme.palette.neutral.main,
            fontWeight: "bold",
            fontSize: "1.3rem",
          },
          "& .Mui-focused input::placeholder": {
            opacity: 1,
          },
          "& textarea::placeholder": {
            color: (theme) => theme.palette.neutral.main,
            fontWeight: "bold",
            fontSize: "1.3rem",
          },
          "& .Mui-focused textarea::placeholder": {
            opacity: 1,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      })}
    </Box>
  );
};

ContactField.propTypes = {
  children: PropTypes.node,
};
