//************************  React import   ************************ //
import React from "react";

//************************ Matrial UI  import   ************************ //
import { Box, Stack, Typography } from "@mui/material";

//************************  Assets import   ************************ //
import PartnerImage from "../svg/PartnerImage";
import { useNavigate } from "react-router-dom";


/////////////////////////////////////////////////////////////////////////////

const PartnersCard = ({ image,url }) => {
  const navigate=useNavigate()

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        borderRadius: "0 60px 0 60px",
        backgroundColor: "#F78A244D",
        border: "1px solid #EF5435",
        boxShadow: "1px 1px 20px  #ef54355c",
        overflow: "hidden",
        width: { xs: "90%", sm: "90%", md: "80%", lg: 300, xl: 300 },
        height: 300,
        boxSizing: "border-box",
        paddingInline: 1,
        overflow: "hidden",
      }}
    >
      <a href={url? url :"https://tv-adviser.com"} target={url?'_blank':"_self"}>
      <Box sx={{ width: "100%" }}  >
        <img src={image} style={{ width: "100%" }}  />
      </Box>
      </a>
    
    </Stack>
  );
};

export default PartnersCard;
