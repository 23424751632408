import { createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "../data/semantics";

export const UserInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    value: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {
          username: { ar: "الحساب الشخصي", en: "personal account" },
          token: "",
          email: "",
          role: "",
        },
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    push: (state, action) => {
      state.value = [...state.value, action.payload];
    },
    remove: (state, action) => {
      state.value = {
        username: "الحساب الشخصي",
        token: "",
        email: "",
        role: LOGOUT,
      };
      localStorage.removeItem("userInfo");
    },
  },
});

export default UserInfoSlice.reducer;
