//************************  React import   ************************ //
import React from "react";

//************************ Redux import   ************************ //
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//************************ Custom Component import   ************************ //
import Button from "./Button";

/////////////////////////////////////////////////////////////////////////////

const FormTable = ({
  children,
  fromHeader,
  submitButtonLabel,
  onSubmit,
  lastString,
  clickString,
  onClick,
  innerWidth = "50%",
  isPending,
  sx = {},
}) => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <Box
        sx={{
          maxWidth: 550,
          width: "100%",
          minHeight: 550,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(15px)",
          boxShadow: "inset 1px 1px 20px  #ef54355c",
          borderRadius: "0 30px 0 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          paddingBlock: 5,
          marginTop: 10,
          ...sx,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#EF5435",
            fontWeight: "bold",
            fontSize: {
              xs: "2rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
              xl: "3rem",
            },
          }}
        >
          {fromHeader}
        </Typography>
        <Stack
          onSubmit={onSubmit}
          component={"form"}
          spacing={2}
          sx={{ width: innerWidth }}
        >
          {children}

          <Stack spacing={2} alignItems={"center"}>
            {isPending ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                disabled={isPending}
                sx={{
                  width: {
                    xs: "80%",
                    sm: "80%",
                    md: "80%",
                    lg: "100%",
                    xl: "100%",
                  },
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                }}
              >
                {submitButtonLabel}
              </Button>
            )}
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.2rem",
                  xl: "1.2rem",
                },
                color: "#fff",
              }}
            >
              {lastString}
              <Box
                onClick={(e) => onClick(e, "id")}
                sx={{
                  color: "#EF5435",
                  display: "inline",
                  cursor: "pointer",
                  paddingInline: 1,
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.2rem",
                    xl: "1.2rem",
                  },
                }}
              >
                {clickString}
              </Box>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FormTable;
