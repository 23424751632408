//************************  React import   ************************ //
import React from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import Button from "./Button";

//************************ Matrial UI  import   ************************ //
import { Box, Stack, Typography } from "@mui/material";

// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";

/////////////////////////////////////////////////////////////////////////////

const TenderCard = ({
  header,
  contract,
  orderNumber,
  field,
  location,
  onClick,
  image,
  id,
}) => {
  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  return (
    <Box
      sx={{
        borderRadius: "0 40px 0 40px",
        border: "1px solid #EF5435",
        boxShadow: "1px 1px 20px  #ef54355c",
        overflow: "hidden",
      }}
    >
      <Box>
        <Box
          sx={{
            borderRadius: "0 30px 0 30px",
            overflow: "hidden",
            height: 240,
            position: "relative",
          }}
        >
          <Box
            component={"img"}
            src={image}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          ></Box>
        </Box>
      </Box>
      <Stack
        sx={{
          textAlign: "center",
          padding: "3rem 3rem",
          height: "max-content",
        }}
        alignItems={"center"}
        spacing={2}
      >
        <Typography variant="h2" color={"primary"} sx={{ fontSize: "2rem" }}>
          {header}
        </Typography>
        <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
          {contract}
        </Typography>
        <Typography variant="h2" sx={{ fontSize: "1.8rem" }}>
          رقم الطلب :
          <Box
            sx={{
              display: "inline",
              color: "#EF5435",
            }}
          >
            {orderNumber}
          </Box>
        </Typography>
        <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
          {field}
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <LocationOnIcon
            color="primary"
            sx={{ width: "2rem", height: "2rem" }}
          />
          <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
            {location}
          </Typography>
        </Stack>

        <Button
          onClick={(e) => onClick(e, id)}
          variant="sm"
          sx={{ width: "14rem" }}
        >
          {global.details[lang]}
        </Button>
      </Stack>
    </Box>
  );
};

export default TenderCard;
