//************************  React import   ************************ //
import React, { Fragment, useState } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Matrial UI  import   ************************ //
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
} from "@mui/material";

// Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";

// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";

/////////////////////////////////////////////////////////////////////////////

const DateInput = ({
  id,
  name,
  variant,
  placeholder,
  multiline = false,
  rows = 3,
  type,
  onValueChange,
  value,
  onBlur,
  error,
  helperText,
  height = "4rem",
  width = "80%",
}) => {
  //************************  Hooks   ************************ //
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null);

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // get lang of all website
  const lang = useSelector((state) => state.lang.value.lang);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  const handleCloseMenu = () => {
    setDatePickerAnchorEl(null);
  };
  const handleOpenDatePicker = (e) => {
    setDatePickerAnchorEl(e.currentTarget);
  };

  //*************************************************************** //

  const openDatePicker = Boolean(datePickerAnchorEl);

  const typeStyle = {
    width: "100%",
    height: height,
    fontSize: "1.5rem",
    color: "#fff",
    paddingInline: "1rem",
  };

  /////////////////////////////////////////////////////////////////////////////

  return (
    <Fragment>
      <TextField
        disabled={true}
        id={`${id}`}
        variant={`${variant}`}
        name={`${name}`}
        placeholder={`${placeholder}`}
        multiline={multiline}
        rows={rows}
        onChange={onValueChange}
        value={value}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleOpenDatePicker}>
                <DateRangeIcon sx={{ color: "#fff" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: width,
          "& .MuiInput-underline": error
            ? {
                border: "1px solid red",
                borderRadius: "0 15px 0 15px ",
              }
            : { border: "1px solid #fff", borderRadius: "0 15px 0 15px " },
          [typeStyle && `& ${type}`]: typeStyle,
          "& .MuiInput-underline::before": {
            width: "0",
            borderColor: "unset",
          },
          "& input.Mui-disabled": {
            WebkitTextFillColor: "#fff",
            opacity: 1,
          },
          "& .MuiInput-underline::after": {
            width: "0",
            borderColor: "unset",
          },

          ".MuiInputLabel-root.Mui-focused": {
            color: "#fff",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1.2rem",
          },
        }}
      />
      <Menu
        open={openDatePicker}
        anchorEl={datePickerAnchorEl}
        onClose={handleCloseMenu}
        sx={{
          direction: lang == "ar" && "rtl",
          "& .MuiButtonBase-root": {
            fontSize: "1.3rem",
            fontWeight: 500,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            // openTo="year"
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={(newValue) =>
              onValueChange(
                new Date(
                  new Date(newValue).getTime() -
                    new Date(newValue).getTimezoneOffset() * 60 * 1000
                ).toISOString()
              )
            }
          />
        </LocalizationProvider>
      </Menu>
    </Fragment>
  );
};

export default DateInput;
