//*************************************  Disable part of page restrictions (pirmitions)   ************************************* //

//************************  React import   ************************ //
import React, { Fragment } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************  Matrial UI import   ************************ //
import { Box } from "@mui/system";

//************************  Other Library import   ************************ //
// props validation
import PropTypes from "prop-types";

/////////////////////////////////////////////////////////////////////////////

const AccessGate = ({ children, roles = [], renderAuth }) => {
  //************************  Redux Selector   ************************ //

  const userInfo = useSelector((state) => state.userInfo.value);
  //*************************************************************** //

  // Check if there is an ID in the user info store or not.
  const isPermitted = roles.some((role) => userInfo.role === role);

  return <>{isPermitted ? children : renderAuth ?? <Fragment></Fragment>}</>;
};

export default AccessGate;

AccessGate.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(
    PropTypes.oneOf(["admin", "owner", "office", "user"])
  ),
  renderAuth: PropTypes.node,
};
