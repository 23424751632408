//*************************************  check if login of not   ************************************* //

//************************  React import   ************************ //
import React from "react";
import { Fragment, useEffect } from "react";
import { Navigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

const authHoc =
  (Component) =>
  (...props) => {
    //************************  Redux Selector   ************************ //
    const userInfo = useSelector((state) => state.userInfo.value);

    //*************************************************************** //

    // Check if there is an ID in the user info store or not.
    const isPermitted = Boolean(userInfo.id);

    /////////////////////////////////////////////////////////////////////////////

    return (
      <Fragment>
        {isPermitted ? <Navigate replace to="/" /> : <Component {...props} />}
      </Fragment>
    );
  };

export default authHoc;
