//************************  React import   ************************ //
import React from "react";

//************************  Matrial UI import   ************************ //
import { Button as MuiButton } from "@mui/material";

//************************  Library import   ************************ //
// props validation
import PropTypes from "prop-types";

/////////////////////////////////////////////////////////////////////////////

const Button = ({ children, variant = "sm", sx = {}, ...props }) => {
  let fontSize;
  switch (variant) {
    case "sm":
      fontSize = "1rem";
      break;
    case "md":
      fontSize = "1.5rem";
      break;
    case "lg":
      fontSize = "2rem";
      break;
    default:
      fontSize = "1rem";
  }

  return (
    <MuiButton
      variant="contained"
      sx={{ fontSize, ...sx }}
      disableRipple
      disableElevation
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;

Button.propTypes = {
  variant: PropTypes.oneOf(["sm", "md", "lg"]),
};
