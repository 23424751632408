//************************  React import   ************************ //
import React from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../../components";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Icons
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import CopyrightIcon from "@mui/icons-material/Copyright";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";

//************************ Constant Data import   ************************ //
import { SUBSCRIBE } from "../../../data/apis";

//************************  Assets import   ************************ //
// Icons
import whatsIcon from "../../../assets/whatsAppIcon.png";
import instaIcon from "../../../assets/InstaIcon.png";
import Logo from "../../../svg/Logo";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

/////////////////////////////////////////////////////////////////////////////

const Footer = () => {
  //************************  Hooks   ************************ //

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const footerLang = useSelector((state) => state.lang.value.home.footer);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  const [request, data] = useFetch({ url: SUBSCRIBE, method: "post" });

  //*************************************************************** //

  //************************  useEffect ************************ //

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  //*************************************************************** //

  //************************  Form Js Code ************************ //
  // Form Validation Schema using Yup library
  const sendEmailSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const { handleChange, values, handleReset, errors, handleSubmit } = useFormik(
    {
      initialValues: {
        email: "",
      },

      validationSchema: sendEmailSchema,
      onSubmit: () => {
        // send data to server
        request({
          onSuccess: (res) => {
            handleReset();
          },
          body: values,
          onFail: (err) => {
            console.log(err);
          },
        });
      },
    }
  );
  //*************************************************************** //

  return (
    <Box
      className="global-padding-y"
      sx={{
        display: "flex",
        justifyContent: "center",

        boxShadow:
          "inset 5px 5px 30px 10px  #F78A244D, inset 5px 5px 30px 10px  #F78A244D",
      }}
    >
      <Wrapper
        sx={{
          width: "90%",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          flexDirection:'column',
          marginInline: "none",
          // bgcolor: "red",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} flexDirection={"row"}>
            <Grid xs={12} sm={12} md={4} lg={5} xl={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Logo style={{ width: "15%", height: "15%" }} />

                <Typography
                  variant="p"
                  sx={{
                    textAlign: "center",
                    fontSize: "1.7rem",
                    width: "100%",
                  }}
                >
                  {footerLang.contact[lang]}
                </Typography>
              </Box>
            </Grid>

            <Grid xs={12} sm={12} md={1} lg={1} xl={1}>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "100%",
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "bold", fontSize: "2rem" }}
                  color={(theme) => theme.palette.primary.main}
                >
                  {footerLang.followUs[lang]}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FacebookRoundedIcon
                    sx={{
                      width: "3.2rem",
                      height: "3.2rem",
                      color: "#1877f2",
                      cursor: "pointer",
                    }}
                  />
                  <Box
                    component={"img"}
                    src={instaIcon}
                    sx={{
                      borderRadius: "50%",
                      width: "3rem",
                      height: "3rem",
                      cursor: "pointer",
                    }}
                  />
                  <Box
                    component={"img"}
                    src={whatsIcon}
                    sx={{
                      borderRadius: "50%",
                      width: "3rem",
                      height: "3rem",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "100%",
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    alignSelf: "start",
                  }}
                  color={(theme) => theme.palette.primary.main}
                >
                  {footerLang.contactUs[lang]}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <LocationOnIcon
                        sx={{
                          width: "3.2rem",
                          height: "3.2rem",
                          color: "#EF5435",
                          cursor: "pointer",
                        }}
                      />

                      <Typography variant="p" sx={{ fontSize: "1.5rem" }}>
                        مؤسسة الرؤي الراقية لتنقية المعلومات جدة المملكة العربية
                        السعودية
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <PhoneEnabledIcon
                        sx={{
                          width: "3.2rem",
                          height: "3.2rem",
                          color: "#EF5435",
                          cursor: "pointer",
                        }}
                      />

                      <Typography variant="p" sx={{ fontSize: "1.5rem" }}>
                        00969593674115
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <EmailIcon
                        sx={{
                          width: "3.2rem",
                          height: "3.2rem",
                          color: "#EF5435",
                          cursor: "pointer",
                        }}
                      />

                      <Typography variant="p" sx={{ fontSize: "1.5rem" }}>
                        info@tv-advisor.com
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={5} lg={3} xl={3}>
              <Box
                sx={{
                  display: "flex",
                  gap: "1.5rem",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {footerLang.importantLinks.header[lang]}
                </Typography>

                <Stack spacing={1}>
                  {footerLang.importantLinks.links.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          bgcolor: "#EF5435",
                        }}
                      ></Box>
                      <Link
                        href={item["link"]}
                        underline="none"
                        target={"blank"}
                        sx={{ fontSize: "1.5rem", color: "#000" }}
                      >
                        {item[lang]}
                      </Link>
                    </Box>
                  ))}
                </Stack>
              </Box>

              {/* Subscrip Button */}
              {/* <Box
                sx={{
                  display: "flex",
                  gap: "1.5rem",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {footerLang.lastNews[lang]}
                </Typography>
                <Typography variant="p" sx={{ fontSize: "2rem" }}>
                  {footerLang.signUp[lang]}
                </Typography>
                <Box
                  component={"form"}
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    border: "1px solid #F78A244D",
                    boxShadow:
                      "inset 1px 1px 5px 2px  #F78A244D , inset 1px 1px 5px 2px  #F78A244D",
                    width: "100%",
                  }}
                >
                  <TextField
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    sx={{
                      width: "100%",
                      "& input::placeholder": { fontSize: "1.5rem" },
                      "& input": { fontSize: "1.5rem" },
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    variant="outlined"
                    placeholder={global.email[lang]}
                  />
                  <Button
                    type="submit"
                    sx={{
                      fontSize: "2rem",
                      borderRadius: "0",
                      color: "#fff",
                      width: "30%",
                      padding: "0",
                    }}
                  >
                    {global.signUp[lang]}
                  </Button>
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="h6"
          sx={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position:"relative",
            bottom:'0%'
          }}
        >
      
          Copyright 2022 by CYPARTA &nbsp; <CopyrightIcon />
        </Typography>
      </Wrapper>
      {data.successAlert}
      {data.failAlert}
    </Box>
  );
};

export default Footer;
