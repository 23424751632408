import { createSlice } from "@reduxjs/toolkit";

export const ConsultanciesDepSlice = createSlice({
  name: "consultanciesDep",
  initialState: {
    value: [],
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    push: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

export default ConsultanciesDepSlice.reducer;
