//************************ Custom Component import   ************************ //
import React from "react";

//************************ Matrial  import   ************************ //
import { TextField } from "@mui/material";

/////////////////////////////////////////////////////////////////////////////

const Inputs = ({
  id,
  name,
  variant,
  placeholder,
  multiline = false,
  rows = 3,
  type,
  onChange,
  value,
  onBlur,
  error,
  helperText,
  height = "4rem",
  width = "80%",
  answerTextColor = "#fff",
  answerBorder = "#fff",
  disabled = false,
  sx = {},
}) => {
  const typeStyle = {
    width: "100%",
    height: height,
    fontSize: "1.5rem",
    color: answerTextColor,
    paddingInline: "1rem",
  };

  const isError = {
    "& .MuiInput-underline": {
      border: "1px solid #fff",
      borderRadius: "0 15px 0 15px ",
    },
  };

  const typeClass = type === "textarea" ? "textarea" : "input";

  // console.log(value);
  type === "textarea" && (typeStyle.paddingBlock = "1.5rem");
  return (
    <TextField
      disabled={disabled}
      id={`${id}`}
      type={type}
      variant={`${variant}`}
      name={`${name}`}
      placeholder={`${placeholder}`}
      multiline={multiline}
      rows={rows}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={{
        width: width,
        ...sx,
        // backdropFilter: "blur(5px)",
        ".Mui-disabled": {
          WebkitTextFillColor: "#000 !important",
          // -webkit-text-fill-color
        },
        "& .MuiInput-underline": error
          ? {
              border: "1px solid red",
              borderRadius: "0 15px 0 15px ",
            }
          : {
              border: `1px solid ${answerBorder}`,
              borderRadius: "0 15px 0 15px ",
            },

        [typeStyle && `& ${typeClass}`]: typeStyle,
        "& .MuiInput-underline::before": {
          width: "0",
          borderColor: "unset",
        },

        "& .MuiInput-underline::after": {
          width: "0",
          borderColor: "unset",
        },

        ".MuiInputLabel-root.Mui-focused": {
          color: "#fff",
        },
        "& .MuiFormHelperText-root": {
          fontSize: 15,
          padding: 1,
          fontWeight: "bold",
        },
        "& input::-webkit-outer-spin-button": { WebkitAppearance: "none" },
        "& input::-webkit-inner-spin-button": { WebkitAppearance: "none" },
      }}
    />
  );
};

export default Inputs;
