//*************************************  Disable page restrictions (pirmitions)   ************************************* //

//************************  React import   ************************ //
import React from "react";
import { Fragment } from "react";
import { Navigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

/////////////////////////////////////////////////////////////////////////////
const pageHoc =
  (Component, roles = [], navigateURL = -1) =>
  (...props) => {
    //************************  Redux Selector   ************************ //
    const userInfo = useSelector((state) => state.userInfo.value);

    //*************************************************************** //

    // Check if user role is in page roles
    const isPermitted = roles.some((role) => userInfo.role === role);

    /////////////////////////////////////////////////////////////////////////////
    return (
      <Fragment>
        {isPermitted ? (
          <Component {...props} />
        ) : (
          <Navigate replace to={navigateURL} />
        )}
      </Fragment>
    );
  };

export default pageHoc;
