import React, { useEffect, useState } from "react";
import "./phoneInput.css"; // Tell webpack that Button.js uses these styles

import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Wrapper } from "../../components";
import FormTable from "../../components/FormTable";
import "react-phone-input-2/lib/style.css";
import CheckIcon from "@mui/icons-material/Check";
// Phone Field
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
///////////////////////

// Show Password Icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Select Icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Form Validation

import { useFormik } from "formik";
import * as Yup from "yup";

// Send Req and navigate
import { useNavigate } from "react-router-dom";
import { CONSULTS, REGISTER, RULES, TENDERACTIVITY } from "../../data/apis";
import useFetch from "../../hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../../components/MultiSelect";
import backgroundImage from "../../assets/Betngan.png";
import authHoc from "../../features/accessibility/hoc/AuthHoc";
import Inputs from "../../components/Inputs";
import AlertDialogSlide from "../../components/AlertDialogSlide";
//////////////////////////////////////////////////////////////////////////////////////////////////
const RegisterForm = () => {
  // Set and Get data from userInfoSlice
  const dispatch = useDispatch();
  const userInfoStore = useSelector((state) => state.userInfo.value);

  ////////////////////////////////////

  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const registerLang = useSelector((state) => state.lang.value.register);
  const global = useSelector((state) => state.lang.value.global);
  /////////////////////

  // Passowrd Toggle
  const [togglePass, SetTogglePass] = useState(false);
  const [togglePassConfirm, SetTogglePassConfirm] = useState(false);
  const togglePassHandler = () => {
    SetTogglePass((prev) => !prev);
  };
  const togglePassConfirmHandler = () => {
    SetTogglePassConfirm((prev) => !prev);
  };
  //////////////////

  // Navigate
  const navigate = useNavigate();

  const handelLogin = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/login`);
  };
  //////////////////////

  const [request, data] = useFetch({ url: REGISTER, method: "post" });

  // From validation
  const registerFormSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(5, "Your password must be longer than 5 characters.")
      .max(25)
      .required("Required"),
    // .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])/,
    //   "Must Contain One Uppercase, One Lowercase"
    // )
    // .matches(
    //   /^(?=.*[!@#\$%\^&\*])/,
    //   "Must Contain One Special Case Character"
    // )
    // .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number")
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    // country: Yup.string().required("Countries Required"),
    city: Yup.string().required("Countries Required"),
    region: Yup.string().required("Region Required"),
    interests: Yup.array("").required("Interests Required"),
    registerRules: Yup.boolean().required("Interests Required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      organization: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      // country: "",
      // countryCode: "",
      region: "",
      city: "",
      interests: [10,41],
      registerRules: false,
    },

    validationSchema: registerFormSchema,
    onSubmit: () => {
      console.log(values);
      // send data to server
      request({
        onSuccess: (res) => {
          setTimeout(() => {
            handelLogin();
            handleReset();
          }, 1500);
        },
        body: {
          ...values,
          interests: values.interests.map((item) => ({ id: item })),
        },
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });

  ///////////////////////////////////////

  // Activity Names

  const tenderActivityStore = useSelector(
    (state) => state.tenderactivity.value
  );
  const [activityReq, activitydata] = useFetch({
    url: TENDERACTIVITY,
    method: "get",
  });

  const handleOpen = () => {
    activityReq({
      onSuccess: (res) => {
        dispatch({ type: "tenderactivity/set", payload: res.data });
      },
    });
  };

  ////////////////////////////

  // Countries

  const [getCountries, setGetCountries] = useState();
  const [countriesReq, Countriesdata] = useFetch({
    url: TENDERACTIVITY,
    method: "get",
  });

  const countriesHandleOpen = () => {
    values.country = "";
    values.city = "";
    countriesReq({
      customBaseUrl: "https://countriesnow.space/api/v0.1/",
      customUrl: "countries/info?returns=countries,dialCode",
      onSuccess: (res) => {
        setGetCountries(res.data.data);
      },
      onFail: (err) => {
        values.countries = [];
        console.log(err);
      },
    });
  };

  ////////////////////////////

  // cities

  const [getCities, setGetCities] = useState();
  const [citiesReq, citiesdata] = useFetch({
    url: TENDERACTIVITY,
    method: "post",
  });

  const citiesHandleOpen = () => {
    // setGetCities();
    citiesReq({
      customBaseUrl: "https://countriesnow.space/api/v0.1/",
      customUrl: "countries/states",
      body: {
        country: `${values.country}`,
      },
      onSuccess: (res) => {
        setGetCities(res.data.data.states);
      },
      onFail: (err) => {
        values.city = [];
        console.log(err);
      },
    });
  };

  // Get Rules
  const [registerRules, setRegisterRules] = useState([]);
  const [registerRulesReq, registerRulesData] = useFetch({
    url: RULES,
    method: "get",
  });

  useEffect(() => {
    registerRulesReq({
      onSuccess: (res) => {
        console.log(res.data);
        setRegisterRules(res.data);
      },
      onFail: (err) => {
        console.log(err);
      },
    });
  }, []);

  ///////////////////////////
  ///////////////////////////////////////////////////////////////

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "fit-content",
        height: "fit-content",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBlock: {
          xs: "8rem",
          sm: "8rem",
          md: "8rem",
          lg: "8rem",
          xl: "2.5rem",
        },
      }}
    >
      <Wrapper
        sx={{
          maXHeight: "100vh",

          marginTop: {
            xs: "0",
            sm: "0",
            md: "60px",
            lg: "60px",
            xl: "60px",
          },
        }}
      >
        <FormTable
          onSubmit={handleSubmit}
          fromHeader={registerLang[lang]}
          submitButtonLabel={registerLang[lang]}
          lastString={registerLang.haveAccount.title[lang]}
          clickString={registerLang.haveAccount.toLogin[lang]}
          onClick={handelLogin}
          isPending={data.isPending}
        >
          <TextField
            id="name"
            label={global.name[lang]}
            variant="standard"
            name="name"
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            sx={{
              "& input": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              "	.MuiInputLabel-root": {
                color: "#fff",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
                margin: 0,
              },
            }}
          />
          <TextField
            id="organization"
            label={global.companyName[lang]}
            variant="standard"
            name="organization"
            onChange={handleChange}
            value={values.organization}
            onBlur={handleBlur}
            error={touched.organization && Boolean(errors.organization)}
            helperText={touched.organization && errors.organization}
            sx={{
              "& input": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              "	.MuiInputLabel-root": {
                color: "#fff",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
                margin: 0,
              },
            }}
          />

          <TextField
            id="email"
            label={global.email[lang]}
            variant="standard"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            sx={{
              "& input": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              "	.MuiInputLabel-root": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
                margin: 0,
              },
            }}
          />

          <PhoneInput
            country={"eg"}
            enableSearch
            value={values.phone}
            onChange={(phone) => setFieldValue("phone", `+${phone}`)}
            containerStyle={{ direction: "ltr" }}
            inputStyle={{
              width: "100%",
              color: "#fff",
              backgroundColor: "transparent",
              border: "unset",
              borderBottom: "1px solid #EF5435",
            }}
            buttonStyle={{
              backgroundColor: "transparent",
              border: "unset",
            }}
            dropdownClass="country" //css class name
            dropdownStyle={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              backdropFilter: "blur(15px)",
              borderRadius: "0 0 0 15px",
            }}
          />

          <Stack spacing={2}>
            <TextField
              id="standard-password-input"
              label={global.password[lang]}
              type={togglePass ? "text" : "password"}
              autoComplete="current-password"
              variant="standard"
              name="password"
              onChange={handleChange}
              value={values.password}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography>
                    <IconButton
                      sx={{ color: "#EF5435" }}
                      disableRipple
                      onClick={togglePassHandler}
                    >
                      {togglePass ? (
                        <VisibilityOffIcon sx={{ color: "#EF5435" }} />
                      ) : (
                        <RemoveRedEyeIcon sx={{ color: "#EF5435" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                "& input": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                "	.MuiInputLabel-root": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                ".MuiInputLabel-root.Mui-focused": {
                  color: "#fff",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                  margin: 0,
                },
              }}
            />
            <TextField
              id="confirm_password"
              label={global.confirmPassword[lang]}
              type={togglePassConfirm ? "text" : "password"}
              variant="standard"
              name="confirm_password"
              onChange={handleChange}
              value={values.confirm_password}
              onBlur={handleBlur}
              error={
                touched.confirm_password && Boolean(errors.confirm_password)
              }
              helperText={touched.confirm_password && errors.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography>
                    <IconButton
                      sx={{ color: "#EF5435" }}
                      disableRipple
                      onClick={togglePassConfirmHandler}
                    >
                      {togglePassConfirm ? (
                        <VisibilityOffIcon sx={{ color: "#EF5435" }} />
                      ) : (
                        <RemoveRedEyeIcon sx={{ color: "#EF5435" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                "& input": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                "	.MuiInputLabel-root": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                ".MuiInputLabel-root.Mui-focused": {
                  color: "#fff",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                  margin: 0,
                },
              }}
            />
            {/* <MultiSelect
              placeholder={global.country[lang]}
              label={true}
              sx={{
                width: "100%",
                backdropFilter: "0",

                "&  .MuiOutlinedInput-input": {
                  fontSize: 15,

                  borderBottom: "1px solid #EF5435",
                  padding: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  color: "#fff",
                },
                "& .MuiInputLabel-root": {
                  color: "#fff",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.2rem",
                    md: "1.5rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  fontSize: 10,
                },
              }}
              value={values.country}
              isPending={Countriesdata.isPending}
              multiple={false}
              onChange={(e) => setFieldValue("country", e.target.value)}
              onOpen={countriesHandleOpen}
              renderValue={(selected) => {
                values.countryCode = getCountries?.find(
                  (item) => item.name === selected
                ).dialCode;

                return getCountries?.find((item) => item.name === selected)
                  .name;
              }}
            >
              {getCountries?.map((item, index) => (
                <MenuItem value={item.name} key={`item ${index}`}>
                  {item.name}
                </MenuItem>
              ))}
            </MultiSelect> */}

            {/* <MultiSelect
              placeholder={global.city[lang]}
              label={true}
              sx={{
                width: "100%",
                backdropFilter: "0",

                "&  .MuiOutlinedInput-input": {
                  fontSize: 15,

                  borderBottom: "1px solid #EF5435",
                  padding: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  color: "#fff",
                },
                "& .MuiInputLabel-root": {
                  color: "#fff",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.2rem",
                    md: "1.5rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  fontSize: 10,
                },
              }}
              value={values.city}
              isPending={citiesdata.isPending}
              multiple={false}
              onChange={(e) => setFieldValue("city", e.target.value)}
              onOpen={citiesHandleOpen}
              renderValue={(selected) => {
                return getCities?.find((item) => item.name === selected).name;
              }}
            >
              {getCities?.map((item, index) => (
                <MenuItem value={item.name} key={`item ${index}`}>
                  {item.name}
                </MenuItem>
              ))}
            </MultiSelect> */}

            <TextField
              id="text"
              label={global.city[lang]}
              variant="standard"
              name="city"
              onChange={handleChange}
              value={values.city}
              onBlur={handleBlur}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && errors.city}
              sx={{
                "& input": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                "	.MuiInputLabel-root": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                ".MuiInputLabel-root.Mui-focused": {
                  color: "#fff",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                  margin: 0,
                },
              }}
            />
            <TextField
              id="text"
              label={global.region[lang]}
              variant="standard"
              name="region"
              onChange={handleChange}
              value={values.region}
              onBlur={handleBlur}
              error={touched.region && Boolean(errors.region)}
              helperText={touched.region && errors.region}
              sx={{
                "& input": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                "	.MuiInputLabel-root": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                ".MuiInputLabel-root.Mui-focused": {
                  color: "#fff",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                  margin: 0,
                },
              }}
            />

            <Box sx={{ minWidth: "100%" }}>
              <MultiSelect
                placeholder={global.chooseBids[lang]}
                label={true}
                sx={{
                  width: "100%",
                  backdropFilter: "0",

                  "&  .MuiOutlinedInput-input": {
                    fontSize: 15,

                    borderBottom: "1px solid #EF5435",
                    padding: {
                      xs: 2,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 2,
                    },
                    color: "#fff",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.2rem",
                      md: "1.5rem",
                      lg: "1.5rem",
                      xl: "1.5rem",
                    },
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    fontSize: 10,
                  },
                }}
                value={values.interests}
                isPending={activitydata.isPending}
                onChange={(e) => setFieldValue("interests", e.target.value)}
                onOpen={handleOpen}
                renderValue={(selected) =>
                  selected
                    .map(
                      (consult) =>
                        tenderActivityStore.find((item) => consult === item.id)
                          ?.name
                    )
                    .join(" - ")
                }
              >
                {tenderActivityStore.map((item, index) => (
                  <MenuItem value={item.id} key={`item ${index}`}>
                    {item.name}
                    {values.interests.find(
                      (interest) => interest === item.id
                    ) && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ width: "100%" }}
                      >
                        <CheckIcon
                          sx={{ color: "#EF5435", fontSize: "2rem" }}
                        />
                      </Stack>
                    )}
                  </MenuItem>
                ))}
              </MultiSelect>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Checkbox
                onChange={(registerRules) =>
                  setFieldValue("registerRules", !values.registerRules)
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 }, color: "#fff" }}
              />

              <AlertDialogSlide
                header={registerLang.registerRules.header[lang]}
                registerRules={registerRules}
              >
                <InputLabel
                  sx={{
                    fontSize: "1.3rem",
                    color: "#fff",
                    cursor: "pointer",
                    "&:hover": { color: "#EF5435" },
                  }}
                >
                  {registerLang.registerRules.accept[lang]}
                </InputLabel>
              </AlertDialogSlide>
            </Box>
          </Stack>
        </FormTable>
      </Wrapper>
      {data.successAlert}
      {data.failAlert}
    </Box>
  );
};

export default authHoc(RegisterForm);
