// const BASEURL = "http://192.168.1.25:8000/";
const BASEURL = "https://api.tv-adviser.com/";
const TENDERS = "mashoora/tender/";
const TENDERACTIVITY = "mashoora/tenderactivity/";
const TENDERCARD = "mashoora/tendercard/";
const CONSULTANCIES = "mashoora/consults/";
const REGISTER = "mashoora/register/";
const LOGIN = "mashoora/login/";
const PASSWORDRESET = "password-reset/";
const RESETPASSWORD = "reset-complete/";
const OFFICE = "mashoora/office/";
const GETOFFICE = "mashoora/getoffice/";
const CONSULTS = "mashoora/consults/";
const CONSULTDEP = "mashoora/consultdep/";
const SUBSCRIBE = "mashoora/subscribe/";
const CONTACT = "mashoora/contact/";
const USERINFO = "mashoora/user/";
const USERS = "mashoora/users/";
const LASTTENDER = "mashoora/last_tender/";
const PARTNERS = "mashoora/partners/";
const RULES = "mashoora/rules/";

export {
  BASEURL,
  TENDERS,
  TENDERACTIVITY,
  TENDERCARD,
  CONSULTANCIES,
  REGISTER,
  LOGIN,
  USERS,
  PASSWORDRESET,
  RESETPASSWORD,
  OFFICE,
  GETOFFICE,
  CONSULTS,
  CONSULTDEP,
  SUBSCRIBE,
  CONTACT,
  USERINFO,
  LASTTENDER,
  PARTNERS,
  RULES,
};
