//************************  React import   ************************ //
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../components";
import FormTable from "../../components/FormTable";

// Authentication
import authHoc from "../../features/accessibility/hoc/AuthHoc";

//************************ Data import   ************************ //
import { LOGIN } from "../../data/apis";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

// Show Password Icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/Betngan.png";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

/////////////////////////////////////////////////////////////////////////////

const LoginForm = () => {
  //************************  Hooks   ************************ //
  const [togglePass, SetTogglePass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const loginLang = useSelector((state) => state.lang.value.login);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  const [request, data] = useFetch({ url: LOGIN, method: "post" });

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // ****  Navigate ****//
  // Navigate to register page
  const handelRegister = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/register`);
  };
  // Navigate to Forgot Password page
  const handelForgotPassword = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/ForgotPassword`);
  };
  // Navigate to Home page
  const handleHome = () => {
    navigate(`/`);
    window.scrollTo({
      top: 0,
    });
  };

  //*************//

  // Toggle Show Password
  const togglePassHandler = () => {
    SetTogglePass((prev) => !prev);
  };
  //*************************************************************** //

  //************************  Form Js Code ************************ //
  // Form Validation Schema using Yup library
  const loginFormSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      timezone: "",
    },

    validationSchema: loginFormSchema,
    onSubmit: () => {
      values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      request({
        onSuccess: (res) => {
          dispatch({ type: "userInfo/set", payload: res.data });
          handleHome();
          handleReset();
        },
        body: values,
        onFail: (err) => {
          console.log(err.response.data);
        },
      });
    },
  });
  //*************************************************************** //
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBlock: {
            xs: "8rem",
            sm: "8rem",
            md: "8rem",
            lg: "8rem",
            xl: "2.5rem",
          },
        }}
      >
        <Wrapper
          sx={{
            maXHeight: "100vh",
          }}
        >
          <FormTable
            onSubmit={handleSubmit}
            fromHeader={loginLang[lang]}
            submitButtonLabel={loginLang[lang]}
            lastString={loginLang.register.title[lang]}
            clickString={loginLang.register.toRegister[lang]}
            onClick={handelRegister}
            isPending={data.isPending}
          >
            <TextField
              id="username"
              label={global.email[lang]}
              variant="standard"
              name="username"
              onChange={handleChange}
              value={values.username}
              onBlur={handleBlur}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{
                "& input": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                "	.MuiInputLabel-root": {
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  color: "#fff",
                },
                ".MuiInputLabel-root.Mui-focused": {
                  color: "#fff",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                },
              }}
            />
            <Stack spacing={2}>
              <TextField
                id="standard-password-input"
                label={global.password[lang]}
                type={togglePass ? "text" : "password"}
                autoComplete="current-password"
                variant="standard"
                name="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography>
                      <IconButton
                        sx={{ color: "#EF5435" }}
                        disableRipple
                        onClick={togglePassHandler}
                      >
                        {togglePass ? (
                          <VisibilityOffIcon sx={{ color: "#EF5435" }} />
                        ) : (
                          <RemoveRedEyeIcon sx={{ color: "#EF5435" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  "& input": {
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.5rem",
                      xl: "1.5rem",
                    },
                    color: "#fff",
                    "& input::-ms-reveal": {
                      display: "none",
                    },
                    "& input::-ms-clear": {
                      display: "none",
                    },
                  },
                  "	.MuiInputLabel-root": {
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.5rem",
                      xl: "1.5rem",
                    },
                    color: "#fff",
                  },
                  ".MuiInputLabel-root.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />

              <Typography
                onClick={handelForgotPassword}
                variant="h4"
                sx={{
                  width: "fit-content",
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.2rem",
                    xl: "1.2rem",
                  },
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": { color: "#EF5435" },
                }}
              >
                {loginLang.forgotPassword[lang]}
              </Typography>
            </Stack>
          </FormTable>
        </Wrapper>
        {data.successAlert}
        {data.failAlert}
      </Box>
    </Fragment>
  );
};

export default authHoc(LoginForm);
