//************************  React import   ************************ //
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../components";
import TenderCard from "../../components/TenderCard";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";
import pageHoc from "../../features/accessibility/hoc/PageHoc";

// import useScroll from "../../hooks/useScroll";

//************************  Matrial UI import   ************************ //
import { Box, CircularProgress, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

//************************ Constant Data import   ************************ //
import { TENDERCARD, TENDERS } from "../../data/apis";
import { ALLACCESS, OFFICE_USER } from "../../data/semantics";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/GetProfitBaner.jpg";

///////////////////////////////////////////////////////////////////////////////////////////

const AllTenders = () => {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // const scrollY = useScroll();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);

  // Get tenders data from store
  const tendersStore = useSelector((state) => state.tenders.value);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get tenders data from server
  const [request, data] = useFetch({ url: TENDERCARD, method: "get" });

  //*************************************************************** //

  //************************  useEffect ************************ //

  // Fire get tenders request
  const getTenders = () => {
    request({
      params: {
        page: tendersStore.current <= 1 ? tendersStore.current + 1 : 1,
      },
      onSuccess: (res) => {
        dispatch({ type: "tenders/set", payload: res.data });
      },
    });
  };

  useEffect(() => {
    getTenders();
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // Navigate to specific tender details
  const handleAddTenders = (_, id) => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/tenders/${id}`);
  };

  //*************************************************************** //

  return (
    <Box className="global-padding-y " sx={{ paddingTop: 25 }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "calc(15vh)",
          backgroundImage: `url("${backgroundImage}")`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></Box>
      <Wrapper>
        <Stack spacing={4} alignItems={"center"}>
          <Heading>{global.tender[lang]}</Heading>

          <Stack>
            <Grid container spacing={4}>
              {tendersStore?.results.map((item) => (
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <TenderCard
                    key={item.id}
                    id={item.id}
                    header={item.tender_type && item.tender_type.name}
                    contract={item.description}
                    orderNumber={item.competition_number}
                    field={item.type}
                    location={item.submit_place}
                    image={item.tender_type_image}
                    onClick={handleAddTenders}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          {data.isPending ? (
            <CircularProgress />
          ) : (
            tendersStore.next && (
              <Button
                sx={{ fontSize: "2rem", paddingInline: 5 }}
                onClick={getTenders}
              >
                {global.more[lang]}
              </Button>
            )
          )}
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default pageHoc(AllTenders, ALLACCESS, "/login");
