import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const SubCard = ({ item, subscripNow, freeTrail }) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.value);
  // Get lang from redux
  const lang = useSelector((state) => state.lang.value.lang);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "0 40px 0 40px",
        border: "1px solid #EF5435",
        boxShadow: "1px 1px 20px  #ef54355c",
        height: 250,
      }}
    >
      <Stack
        sx={{
          textAlign: "center",
          height: "70%",
          maxHeight: 200,
          padding: "5rem",
        }}
        spacing={4}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h4">{item[lang]}</Typography>
        <Typography variant="h4">{`${item["paid"]}$`}</Typography>

        <Button
          sx={{ fontSize: "1.5rem" }}
          onClick={() => {
            navigate("/login");
          }}
          disabled={userInfo.token}
        >
          {!userInfo.token ? subscripNow[lang] : freeTrail[lang]}
        </Button>
      </Stack>
    </Box>
  );
};

export default SubCard;
