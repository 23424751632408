import { createSlice } from "@reduxjs/toolkit";

export const LangSlice = createSlice({
  name: "lang",
  initialState: {
    value: {
      lang: localStorage.getItem("lang")
        ? JSON.parse(localStorage.getItem("lang"))
        : "ar",

      logout: {
        ar: "تسجيل الخروج",
        en: "Logout",
      },

      home: {
        hero: {
          header: {
            ar: "تبحث عن مناقصات, أنظمة, مشاريع,استشارات",
            en: "Looking for tenders, systems, projects, consultations",
          },
          subHeader: {
            ar: "مرحبا بك في بيت المشورة",
            en: "Welcome to the House of Advice",
          },
        },
        addTender: {
          header: {
            ar: "لديك مشاريع و تفكر في تنفيذها؟ابدأ مع",
            en: "You have projects and you are thinking of implementing them? Get started with",
          },
          mashora: { ar: " بيت المشورة", en: " Bait al-Mashura." },
        },

        addConsultancie: {
          header: {
            ar: "لديك مشكلة و تفكر في حلها؟ ابدأ مع",
            en: "You have a problem and you are thinking of solving it? start with",
          },
          mashora: { ar: " بيت المشورة", en: " Bait al-Mashura." },
        },
        aboutUs: {
          header: { ar: "من نحن", en: "About Us" },
          subHeader: {
            ar: "بيت المشورة  tv-adviser  هي منصة تقدم : ",
            en: "Bait Al-Mashura tv-adviser is a platform that offers:",
          },
          paragraphOne: {
            ar: "خدمات اعمال لارسال المناقصات والمنافسات العامة والخاصة والفرص الاستثمارية المختلفة للأعضاء المسجلين في المنصة في المملكة العربية السعودية او الدول الأخرى حسب الأنشطة المتوافقة مع أعمالهم.",
            en: "Business services do not send tenders, public and private competitions, and various investment opportunities for members registered on them in the Kingdom of Saudi Arabia or other countries according to the activities compatible with their business.",
          },
          paragraphTwo: {
            ar: "يتم جمع المناقصات و المنافسات من جهات مختلفة او التي تم اضافتها من الأعضاء وتبويبها بما يناسب و ارسالها ليكون الاعضاء على اطلاع دائم بالفرص المتاحة كل صباح . ",
            en: "Tenders and competitions from different parties or added by members are collected, categorized and categorized as appropriate and sent to them to be aware of the opportunities available every morning.",
          },
          paragraphThree: {
            ar: "تقديم الاستشارات والاقتراحات الى الأعضاء مجانا وهي استشارات غير ملزمة للمتلقي فقط قد تساعد الاعضاء في أعمالهم ومجالها الاستشارات القانونية او العقارية ولبناء والتشييد او التمويلية واستشارات الحاسب الالي والانترنت وما يستجد لاحقا, ويقدمها مكاتب وشركات مرخصة ومعتمدة وذات سمعة ومصداقية لتحقيق اهداف الأعضاء.",
            en: "Providing consultations and suggestions to the members, whether legal, real estate or financing, computer and internet consultations, and what is emerging later....etc. It is provided by licensed and approved offices and companies with a reputation and credibility to achieve the members' goals.",
          },
          paragraphFour: {
            ar: "مؤسسة توب فيجن topvision  التي تملك مجموعة خدمات الكترونية  تقدم هذه الخدمات للأعضاء من قطاع الاعمال و الافراد   ",
            en: "Top Vision Corporation, which owns a group of electronic services, provides these services to members of the business sector and registered individuals",
          },
        },
        contactUs: {
          header: { ar: "تواصل معنا", en: "Connect with us" },
          name: { ar: "الأسم", en: "username" },
          email: { ar: "البريد الاكتروني", en: "Email" },
          service: { ar: "نوع الخدمه", en: "Service" },
          question: { ar: "أترك رسالتك", en: "Leave the question here" },
        },
        partners: { ar: "شركاؤنا", en: "Partners" },

        subscription: {
          header: { ar: "الأشتراك", en: "Subscription" },
          plans: [
            {
              ar: "تجربه لمده 12 شهر",
              en: "Free Trial For 12 Months",
              paid: 90,
            },
            {
              ar: "تجربه لمده 6 شهور",
              en: "Free Trial For 6 Months",
              paid: 60,
            },

            {
              ar: "تجربه لمده 3 شهور",
              en: "Free Trial For A Month",
              paid: 30,
            },
          ],
          subscripNow: {
            ar: "أشترك الان مجانا",
            en: "Subscrip Now Free",
          },
          freeTrail: {
            ar: "تجربه مجانيه",
            en: "Free Trial ",
          },
        },

        footer: {
          contact: {
            ar: "بيت المشورة هو حلقة وصل بين الأعضاء المسجلين ومقدمي الإستشارات المتخصصين لتمكن العضو من تلقي الإستشارة الكترونيا وبخصوصية تامة, كما نبوب ونصنف الفرص الاستثمارية والمناقصات المطروحة من الجهات المختلفة او المضافة من الأعضاء وارسالها يوميا الى جميع الأعضاء لتحقيق اقصى استفادة وتتم العملية الكترونيا (تابع شروط الاستخدام والخصوصية) ",
            en: "Bait Al-Mashura is a link between registered members and specialized advisory providers to enable the member to receive advice remotely and in complete privacy. We also classify investment opportunities and tenders offered by various parties or added by members and send them daily to all members to achieve maximum benefit and the process is completed remotely (Read the terms of use and privacy)",
          },
          followUs: {
            ar: "تابعنا",
            en: "follow Us",
          },
          contactUs: {
            ar: "تواصل معنا",
            en: "Connect with us",
          },
          lastNews: {
            ar: "اخر العروض والاخبار",
            en: "Latest offers and news",
          },
          signUp: {
            ar: "اشترك في القائمة البريدية و استمتع بقسائم الخصم و العضويات المجانية الحصرية للمشتركين",
            en: "Subscribe to the mailing list and enjoy exclusive discount vouchers and free memberships for subscribers",
          },

          importantLinks: {
            header: { ar: "روابط تهمك", en: "Important Links" },
            links: [
              {
                ar: "هيئة الخبراء بمجلس الوزراء",
                en: "Top Vision Corporation, which owns a group of electronic services, provides these services to members of the business sector and registered individuals",
                link: "https://www.boe.gov.sa/ar/Pages/default.aspx",
              },
              {
                ar: "وكالة الأنباء السعودية",
                en: "Saudi Press Agency",
                link: "https://www.spa.gov.sa/",
              },
              {
                ar: "المركز الوطني للوثائق والمحفوظات",
                en: "National Center for Documents and Archives",
                link: "https://ncar.gov.sa/",
              },
              {
                ar: "منصة تواصل",
                en: "communication platform",
                link: "https://tawasol.royalcourt.gov.sa/",
              },
              {
                ar: "منصة استطلاع",
                en: "survey platform",
                link: "https://istitlaa.ncc.gov.sa/ar/Pages/default.aspx",
              },
            ],
          },
        },
      },

      login: {
        ar: "تسجيل دخول",
        en: "Login",
        forgotPassword: { ar: "هل نسيت الرقم السري؟", en: "Forgot Password ?" },
        register: {
          title: { ar: "ليس لديك حساب؟ ", en: "Don't have an account?" },
          toRegister: { ar: "انشاء حساب", en: "Create account" },
        },
      },

      register: {
        ar: "انشاء حساب",
        en: "Create Account",
        haveAccount: {
          title: { ar: "لديك حساب بالفعل؟ ", en: "Already have an account?" },
          toLogin: { ar: "تسجيل الدخول", en: "Login" },
        },
        registerRules: {
          accept: {
            ar: "أوافق على شروط الاستخدام وسياسة الخصوصية ",
            en: "Terms of use",
          },
          header: {
            //  ar : "أوافق على شروط الاستخدام وسياسة الخصوصية ",
            //   en: "Terms of use",
            title: {
              ar: "اتفاقية شروط الاستخدام",
              en: "Terms of Use",
            },
            content: {
              ar: " هذه اتفاقية بين العميل ( العضو المستخدم للموقع ) و يشار الية بالمستخدم  و بين (مؤسسة الرؤى الراقية لتقنية المعلومات  )  و يشار اليها ب توب فيجن  top vision  المالك لموقع بيت المشورة tv-adviser.com  و يشار اليه بالموقع و إن تسجيلك في الموقع كعضو و مستخدم  يعني إقرارك بأنك قرأت وفهمت و وافقت على هذه الاتفاقية .",
            },
          },
        },
      },

      forgotPassowrd: {
        ar: "هل نسيت كلمة المرور؟",
        en: "Did you forget your password?",
      },

      changePassword: {
        ar: "تعديل كلمة المرور",
        en: "Change Password",
      },
      dataTable: {
        activity: { ar: "النشاط", en: "Activity" },
        announcementDate: { ar: "تاريخ الاعلان", en: "Announcement date" },
        competitionNo: { ar: "رقم المنافسة", en: "Competition No." },
        tenderSubject: { ar: "موضوع المناقصة", en: "Tender subject" },
        competitionType: { ar: "نوع المنافسة", en: "Competition type" },
        advertiserName: { ar: "اسم الجهة المعلنة", en: "Advertiser name" },
        city: { ar: "المدينة", en: "City" },
        placeOfSubmission: { ar: "مكان التقديم", en: "Place of submission" },
        bidRequirements: {
          ar: "قيمة كراسة الشروط",
          en: "The value of the bid requirements",
        },
        termsSheet: {
          ar: "مكان بيع كراسة الشروط",
          en: "Where to sell terms sheet",
        },
        phone: {
          ar: "الهاتف",
          en: "Phone",
        },
        deadline: {
          ar: "اخر موعد لتقديم العطاء",
          en: "Deadline for submitting a bid",
        },
        openingTheEnvelopes: {
          ar: "موعد فتح المظاريف",
          en: "The date of opening the envelopes",
        },
        extensionDeadline: {
          ar: "تمديد موعد تقديم العطاء",
          en: "Extension of bid submission deadline",
        },
        extensionOpeningTheEnvelopes: {
          ar: "تمديد موعد فتح المظاريف",
          en: "Extension of opening date",
        },
        source: {
          ar: "المصدر",
          en: "Source",
        },
        tenderCode: {
          ar: "كود المناقصه",
          en: "Tender code",
        },
      },

      addConsultancie: {
        consultancieType: {
          ar: "نوع الاستشاره",
          en: "type of counseling?",
        },
        office: {
          ar: "المكتب",
          en: "Office",
        },
        leaveConsultancie: {
          ar: "موضوع الاستشاره",
          en: "consultancie topic",
        },
        consultancieDetails: {
          ar: "تفاصيل الاستشارة",
          en: "Consultation details",
        },
        image: {
          ar: "اضف صوره",
          en: "Add image",
        },
        addFile: {
          ar: "اضف ملف",
          en: "Add File",
        },
        filePreview: { ar: "اسم الملف", en: "File Name" },
      },

      addTenders: {
        tender_type_id: {
          ar: "النشاط",
          en: "Activity?",
        },
        requriemnts_sale_place: {
          ar: "مكان بيع كراسة الشروط",
          en: "Where to sell terms sheet",
        },
        created_date: {
          ar: "تاريخ الاعلان",
          en: "Announcement date",
        },
        phone: {
          ar: "الهاتف",
          en: "Phone",
        },
        description: {
          ar: "موضوع المنافسة",
          en: "Competition tobic",
        },
        Deadline: {
          ar: "اخر موعد لتقديم العطاء",
          en: "Deadline for submitting a bid",
        },
        type: {
          ar: "نوع المنافسة",
          en: "Competition type",
        },
        envelope_opening_date: {
          ar: "موعد فتح المظاريف",
          en: "The date of opening the envelopes",
        },
        Organization_name: {
          ar: "اسم الجهة المعلنة",
          en: "Advertiser name",
        },
        Deadline_extend: {
          ar: "تمديد موعد تقديم العطاء",
          en: "Extension of bid submission deadline",
        },
        city: {
          ar: "المدينة",
          en: "City",
        },
        envelope_opening_extend: {
          ar: "تمديد موعد فتح المظاريف",
          en: "Extension of opening date",
        },
        submit_place: {
          ar: "مكان التقديم",
          en: "Place of submission",
        },
        reference: {
          ar: "المصدر",
          en: "Source",
        },
        requirements_val: {
          ar: "قيمة كراسة الشروط",
          en: "The value of the bid requirements",
        },
        code: {
          ar: "رقم المناقصه",
          en: "Bid number",
        },
        note: {
          ar: "ملاحظات",
          en: "notes",
        },
        image: {
          ar: "اضف صوره",
          en: "Add image",
        },
        addFile: {
          ar: "اضف ملف",
          en: "Add File",
        },
        filePreview: { ar: "اسم الملف", en: "File Name" },
      },

      global: {
        Consultancies: {
          ar: "الاستشارات",
          en: "Consultancies",
        },
        tender: {
          ar: "المناقصات",
          en: "Tenders",
        },
        addTender: { ar: "أضف مناقصة", en: "Add Tender" },
        addConsultancie: { ar: "أطلب أستشارة", en: "Ask for a consultation" },
        showAll: {
          ar: "عرض الجميع",
          en: "Show All",
        },
        send: {
          ar: "إرسال",
          en: "Send",
        },
        save: {
          ar: "حفظ",
          en: "Save",
        },
        cancel: {
          ar: "إغلاق",
          en: "Cancel",
        },
        more: { ar: "المزيد", en: "More" },
        search: { ar: "بحث...", en: "Search..." },
        answer: {
          ar: "الإجابه",
          en: "Answer",
        },
        answerForClint: {
          ar: "لم يتم الرد من قبل المكتب حتي الان",
          en: "No response from the office yet",
        },
        answerForOffice: {
          ar: "اترك اجابتك هنا",
          en: "Leave your answer here",
        },
        tenderNotFound: { ar: "لا توجد مناقصات", en: "Tenders not found" },
        consultNotFound: {
          ar: "لا توجد استشارات",
          en: "Consultancies not found",
        },
        details: {
          ar: "التفاصيل",
          en: "Details",
        },
        signUp: {
          ar: "إشترك",
          en: "Sign Up",
        },
        notFound: {
          ar: "لا يوجد",
          en: "not found",
        },

        name: { ar: "الأسم", en: "Name" },
        email: {
          ar: "البريد الاكتروني",
          en: "Email",
        },
        password: {
          ar: "كلمة السر",
          en: "Password",
        },
        confirmPassword: {
          ar: "تأكيد كلمة السر",
          en: "Confirm Password",
        },
        companyName: {
          ar: "اسم الشركه",
          en: "Company Name",
        },
        phone: {
          ar: "رقم الهاتف",
          en: "Phone Number",
        },
        country: {
          ar: "الدوله",
          en: "country",
        },
        city: {
          ar: "المدينه",
          en: "City",
        },
        region: {
          ar: "المنطقه",
          en: "Region",
        },
        chooseBids: {
          ar: "أختر المناقصات المناسبة",
          en: "Choose the right bids",
        },
      },
    },
  },
  reducers: {
    toggleLang: (state, action) => {
      if (
        Boolean(action.payload) &&
        action.payload !== "en" &&
        action.payload !== "ar"
      )
        throw new Error(
          "The only possible languages to toggle between are either ar or en"
        );
      if (action.payload) {
        state.value.lang = action.payload;
        localStorage.setItem("lang", JSON.stringify(action.payload));
      } else {
        switch (state.value.lang) {
          case "en":
            state.value.lang = "ar";
            break;
          case "ar":
            state.value.lang = "en";
            break;
          default:
            state.value.lang = "en";
        }
      }
    },
  },
});

export default LangSlice.reducer;
