//************************  React import   ************************ //
import * as React from "react";
import { Fragment } from "react";

//************************ Matrial UI  import   ************************ //
import { Box, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Styles
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
    sx: {
      color: "#fff",

      height: 300,
      // bgcolor: "rgba(128 ,47 ,31,0.5)",
      // backdropFilter: "blur(5px)",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      backdropFilter: "blur(15px)",
      borderRadius: "0 0 0 15px",

      "& .MuiMenuItem-root": {
        fontSize: {
          xs: "1.2rem",
          sm: "1.2rem",
          md: "1.3rem",
          lg: "1.3rem",
          xl: "1.3rem",
        },
      },
      "& .MuiMenuItem-root:hover": {
        bgcolor: "rgba(128 ,47 ,31,0.8)",
      },
      // "& .Mui-selected": {
      //   backgroundColor: "rgb(128 47 31)!important",
      // },
      "& .Mui-selected:hover": {
        backgroundColor: "rgb(128 47 31)!important",
      },
    },
  },
};

/////////////////////////////////////////////////////////////////////////////

export default function MultiSelect({
  onChange,
  value = [],
  renderValue,
  placeholder = "",
  label = false,
  children,
  sx = {},
  isPending,
  multiple = true,
  onOpen,
  selected,
  error,
  ...props
}) {
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          label={label && placeholder}
          select
          value={value}
          onChange={onChange}
          error={error}
          helperText={error}
          // InputLabelProps={{
          //   style: { color: "#fff", fontSize: 15 },
          // }}
          sx={{
            bgcolor: "transparent",
            minHeight: "100%",

            "& .MuiFormHelperText-root": {
              fontSize: "1.5rem",
            },
            "& .MuiSelect-select": {
              minHeight: "1rem",
              padding: 0,
            },
            "&  .MuiOutlinedInput-input": {
              fontSize: {
                xs: "1.2rem",
                sm: "1.2rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              borderRadius: "0 15px 0 15px",
              border: "1px solid #fff",
              padding: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },
              color: "#fff",
            },
            "&  .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&  .MuiSvgIcon-root": {
              color: "#fff",
            },
            "& .MuiPaper-root": {
              bgcolor: "#000",
            },
            "& .MuiTypography-root": {
              fontSize: {
                xs: "1.2rem",
                sm: "1.2rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
            },
            ...sx,
          }}
          SelectProps={{
            defaultValue: "",
            displayEmpty: true,
            multiple: multiple,
            onOpen: onOpen,
            renderValue: (selected) => {
              if (!selected || (Array.isArray(selected) && !selected?.length)) {
                return (
                  <Typography sx={{ opacity: 0.5 }}>
                    {!label && placeholder}
                  </Typography>
                );
              } else {
                return renderValue ? (
                  <Typography>{renderValue(selected)} </Typography>
                ) : (
                  <Typography>{selected} </Typography>
                );
              }
            },
            MenuProps: MenuProps,
          }}
          {...props}
        >
          {isPending ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : children?.length !== 0 ? (
            children
          ) : (
            <MenuItem disabled>فارغ</MenuItem>
          )}
        </TextField>
      </div>
    </Fragment>
  );
}

{
  /*
  <FormControl
    sx={{
      width: width,
      borderBottom: fromBorderBottom,
    }}
  >
    <Select
      name={name}
      onOpen={onOpen}
      multiple={multiple}
      displayEmpty
      value={personName}
      onChange={handleChange}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>{Placeholder}</em>;
        }

        return selected.join(", ");
      }}
      MenuProps={MenuProps}
      inputProps={{ "aria-label": "Without label" }}
      sx={{
        backdropFilter: "blur(5px)",
        ...sx,
        "&": {
          border: 0,
        },
        "&  .MuiOutlinedInput-input": {
          padding: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: inputPadding,
            xl: inputPadding,
          },
          borderRadius: inputBorderRadius,
          border: border,
          fontSize: {
            xs: "1.2rem",
            sm: "1.2rem",
            md: "1.5rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
          color: "#fff",
        },
        "&  .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&  .MuiSvgIcon-root": {
          color: "#fff",
        },
        "& .MuiPaper-root": {
          bgcolor: "#000",
        },
      }}
    >
      <MenuItem disabled value="" sx={{}}>
        <em>{Placeholder}</em>
      </MenuItem>
      {isPending ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        names.map((item) => (
          <MenuItem
            key={item}
            value={item}
            style={getStyles(item, personName, theme)}
          >
            {item}
          </MenuItem>
        ))
      )}
    </Select>
    {helperText && (
      <Typography
        sx={{
          fontSize: 15,
          color: "red",
          display: "block",
          alignSelf: "flex-start",
          padding: 1,
          fontWeight: "bold",
          color: "#d23131",
        }}
      >
        {helperText}
      </Typography>
    )}
  </FormControl>;
      */
}
