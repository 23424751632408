const ObjNotFalse = (obj) => {
  let finalObj = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value !== false) {
      finalObj[`${key}`] = value;
    }
  }
  return finalObj;
};

export default ObjNotFalse;
