import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ children, header, registerRules }) {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);
   const Rulesheader=useSelector((state)=>state.lang.value.register.registerRules.header)
 
  //*************************************************************** //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box onClick={handleClickOpen}>{children}</Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ fontSize: "2rem", color: "#EF5435", textAlign: "center" }}
        >
          {Rulesheader.title[lang]}
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Typography
              component="h3"
              sx={{
                fontSize: "1.5rem",
                color: "red",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {Rulesheader.title[lang]}
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: "1.3rem",
                color: "#000",
                opacity: 1,
                fontWeight: "500",
              }}
            >
              {Rulesheader.content[lang]}
            </Typography>
          </Stack>
          <ol>
            {registerRules &&
              registerRules.map((item) => (
                <Box
                  key={`registerRules ${item.id}`}
                  sx={{ paddingBlock: "1rem" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "1.5rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <ul>
                    <Box
                      component={"li"}
                      sx={{
                        fontSize: "1.3rem",
                        color: "#000",
                        opacity: 1,
                        fontWeight: "500",
                      }}
                    >
                      {item.content}
                    </Box>
                  </ul>
                </Box>
              ))}
          </ol>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ color: "#fff", fontSize: "1.5rem", width: "40%" }}
            onClick={handleClose}
          >
            {global.cancel[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
