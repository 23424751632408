//************************  React import   ************************ //
import React from "react";

//************************ Matrial UI  import   ************************ //
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";

/////////////////////////////////////////////////////////////////////////////

const Wrapper = ({ children, sx = {} }) => {
  //************************  Hooks   ************************ //
  const md = useMediaQuery("(max-width: 1440px)");

  //*************************************************************** //

  return (
    <Box
      sx={{
        width: md ? "80vw" : 1170,
        marginInline: "auto",

        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
