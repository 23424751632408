import { Box, Stack } from "@mui/material";
import React from "react";
import { Heading, Wrapper } from "../../../components";
import SubCard from "../../../components/SubCard";
import Grid from "@mui/material/Unstable_Grid2";
import { useSelector } from "react-redux";

const Sub = () => {
  // Get lang from redux
  const lang = useSelector((state) => state.lang.value.lang);
  const subscriptionLang = useSelector(
    (state) => state.lang.value.home.subscription
  );
  const global = useSelector((state) => state.lang.value.global);

  return (
    <Box className="global-padding-y" id="Subscription">
      <Wrapper>
        <Stack alignItems={"center"} spacing={4}>
          <Heading>{subscriptionLang.header[lang]}</Heading>
          <Stack>
            <Grid container spacing={4}>
              {subscriptionLang.plans.map((item, index) => (
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <SubCard
                    item={item}
                    subscripNow={subscriptionLang.subscripNow}
                    freeTrail={subscriptionLang.freeTrail}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default Sub;
