//************************  React import   ************************ //
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../components";
import FormTable from "../../components/FormTable";

import authHoc from "../../features/accessibility/hoc/AuthHoc";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

//************************ Constant Data import   ************************ //
import { RESETPASSWORD } from "../../data/apis";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/Betngan.png";

// Show Password Icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangePassword = () => {
  //************************  Hooks   ************************ //
  // Toggle show password and confirm password
  const [togglePass, SetTogglePass] = useState(false);
  const [togglePassConfirm, SetTogglePassConfirm] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Get token and id from url
  const { id, token } = useParams();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const changePasswordLang = useSelector(
    (state) => state.lang.value.changePassword
  );
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Send passowrd to server
  const [request, data] = useFetch({
    url: RESETPASSWORD,
    method: "patch",
    successMessage: "تم تغيير الباسورد",
  });

  //*************************************************************** //

  //************************  useEffect ************************ //

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // toggle password and confirm password functions
  const togglePassHandler = () => {
    SetTogglePass((prev) => !prev);
  };
  const togglePassConfirmHandler = () => {
    SetTogglePassConfirm((prev) => !prev);
  };

  // Navigate to Login Page
  const handelLogin = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/login`);
  };

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // Validation Schema using Yup
  const changePasswordSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
  } = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      uidb64: id,
      token: token,
    },

    validationSchema: changePasswordSchema,
    onSubmit: () => {
      request({
        body: values,
        onSuccess: (res) => {
          setTimeout(() => {
            handelLogin();
          }, 2500);
          handleReset();
        },
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });
  //*************************************************************** //

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Wrapper>
        <FormTable
          onSubmit={handleSubmit}
          fromHeader={changePasswordLang[lang]}
          submitButtonLabel={global.save[lang]}
        >
          {data.isPending ? (
            <CircularProgress color="secondary" />
          ) : (
            <Stack spacing={2}>
              <TextField
                id="standard-password-input"
                label={global.password[lang]}
                type={togglePass ? "text" : "password"}
                autoComplete="current-password"
                variant="standard"
                name="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography>
                      <IconButton
                        sx={{ color: "#EF5435" }}
                        disableRipple
                        onClick={togglePassHandler}
                      >
                        {togglePass ? (
                          <VisibilityOffIcon sx={{ color: "#EF5435" }} />
                        ) : (
                          <RemoveRedEyeIcon sx={{ color: "#EF5435" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  "& input": {
                    fontSize: "1.5rem",
                    color: "#fff",
                  },
                  "	.MuiInputLabel-root": {
                    color: "#fff",
                  },
                  ".MuiInputLabel-root.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />
              <TextField
                id="confirm_password"
                label={global.confirmPassword[lang]}
                type={togglePassConfirm ? "text" : "password"}
                autoComplete="current-password"
                variant="standard"
                name="confirm_password"
                onChange={handleChange}
                value={values.confirm_password}
                onBlur={handleBlur}
                error={
                  touched.confirm_password && Boolean(errors.confirm_password)
                }
                helperText={touched.confirm_password && errors.confirm_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography>
                      <IconButton
                        sx={{ color: "#EF5435" }}
                        disableRipple
                        onClick={togglePassConfirmHandler}
                      >
                        {togglePassConfirm ? (
                          <VisibilityOffIcon sx={{ color: "#EF5435" }} />
                        ) : (
                          <RemoveRedEyeIcon sx={{ color: "#EF5435" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  "& input": {
                    fontSize: "1.5rem",
                    color: "#fff",
                  },
                  "	.MuiInputLabel-root": {
                    color: "#fff",
                  },
                  ".MuiInputLabel-root.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />
            </Stack>
          )}
        </FormTable>
      </Wrapper>
      {data.successAlert}
      {data.failAlert}
    </Box>
  );
};

export default authHoc(ChangePassword);
