/** @jsx jsx */

//************************  React import   ************************ //
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../components";
import Button from "../components/Button";

//************************  Matrial UI import   ************************ //
import {
  AppBar,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
// Icons
import MenuIcon from "@mui/icons-material/Menu";

//************************ Constant Data import   ************************ //
import pages from "../data/pages";

//************************  Assets import   ************************ //
import Logo from "../svg/Logo";

//************************  Library import   ************************ //
import { HashLink } from "react-router-hash-link";
import { css, jsx } from "@emotion/react";
import NavDrawer from "../components/NavDrawer";

import { Link } from "react-scroll";
import AccessGate from "../features/accessibility/components/AccessGate";
import { ADMIN, LOGOUT, OFFICE_USER, USER } from "../data/semantics";

/////////////////////////////////////////////////////////////////////////////

const Topnav2 = () => {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const sm = useMediaQuery("(min-width: 1200px)");

  const [isOpen, setIsOpen] = useState(false);

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang); // en , ar
  const addButtons = useSelector((state) => state.lang.value.home);
  const global = useSelector((state) => state.lang.value.global);

  const token = useSelector((state) => state.userInfo.value.token);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // ****  Navigate ****//

  // Navigate to Add Tender page
  const handelAddTender = () => {
    navigate("/AddSingleTenders");
    setIsOpen(false);
  };

  // Navigate to Add Consultancie page
  const handelAddConsultancie = () => {
    navigate("/AddSingleConsultancy", { state: { name: "" } });
    setIsOpen(false);
  };

  const handelHome = () => {
    navigate("/");
  };

  //*************//

  //*************************************************************** //

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        backgroundColor: "unset",
        position: "absolute",
        overflow: "hidden",
        top: 35,
        right: 0,
      }}
    >
      <Wrapper>
        <Toolbar
          sx={{
            paddingBlock: 1,
            paddingInline: "0 !important",
            boxSizing: "border-box",
          }}
        >
          <Box
            onClick={handelHome}
            sx={{
              // bgcolor: "#fff",
              minWidth: 50,
              maxWidth: 50,
              opacity: {
                xs: 0,
                sm: 0,
                md: 1,
                lg: 1,
                xl: 1,
              },
              cursor: "pointer",
            }}
          >
            <Logo
              style={{
                width: "120%",
                height: "120%",
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: 10,
              }}
            />
          </Box>
          {sm && (
            <>
              <Stack direction="row" sx={{ marginInline: 5 }}>
                {pages.map((page, index) => {
                  if (!page.hasOwnProperty("name")) return;
                  return (
                    <NavLink
                      key={`navlink ${index}`}
                      to={
                        page.hashLink === "/#consultancies"
                          ? token
                            ? page.hashLink
                            : "/login"
                          : page.hashLink
                      }
                      active={
                        location.pathname.match(/\/[a-z0-9]*/gi)[0] ===
                        page.path
                      }
                    >
                      {page.name[lang]}
                    </NavLink>
                  );
                })}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Button sx={{ fontSize: "1.5rem" }} onClick={handelAddTender}>
                    {global.addTender[lang]}
                  </Button>

                  <Button
                    sx={{ fontSize: "1.5rem" }}
                    onClick={handelAddConsultancie}
                  >
                    {global.addConsultancie[lang]}
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {/* navbar */}
          {!sm && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <MenuIcon sx={{ color: "white" }} fontSize="large" />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Wrapper>
      <Drawer
        anchor="top"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "transparent",
            color: "#fff",
          },
        }}
      >
        <Box
          p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",

            backgroundImage:
              "linear-gradient(10deg, #ffffff3d, #ffffff30, #ffffff3d)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Box
            sx={{
              minWidth: 50,
              maxWidth: 50,

              marginBlock: "1rem",
            }}
          >
            <Logo style={{ width: "100%", height: "100%" }} />
          </Box>
          <>
            <Stack sx={{ marginInline: 5, alignItems: "center", gap: 2 }}>
              {pages.map((page, index) => {
                if (!page.hasOwnProperty("name")) return;
                return (
                  // <Box
                  //   component={"NavLink"}
                  //   key={`navlink ${index}`}
                  //   to={
                  //     page.hashLink === "/#consultancies"
                  //       ? token
                  //         ? page.hashLink
                  //         : "/login"
                  //       : page.hashLink
                  //   }
                  //   active={
                  //     location.pathname.match(/\/[a-z0-9]*/gi)[0] === page.path
                  //   }
                  // >
                  //   {page.name[lang]}
                  // </Box>

                  <Link
                    style={{ fontSize: "1.5rem" }}
                    smooth={true}
                    offset={50}
                    duration={500}
                    to={
                      page.hashLink === "consultancies"
                        ? token
                          ? page.hashLink.split("/#")[1]
                          : "/login"
                        : page.hashLink.split("/#")[1]
                    }
                    onClick={() => {
                      location.pathname !== "/" &&
                        navigate(page.hashLink.split("/#")[1]);

                      setIsOpen(false);
                    }}
                  >
                    {page.name[lang]}
                  </Link>
                );
              })}

              <Button sx={{ fontSize: "1.5rem" }} onClick={handelAddTender}>
                {global.addTender[lang]}
              </Button>

              <Button
                sx={{ fontSize: "1.5rem" }}
                onClick={handelAddConsultancie}
              >
                {global.addConsultancie[lang]}
              </Button>
            </Stack>
          </>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Topnav2;

const NavLink = ({ children, active, ...props }) => {
  return (
    <HashLink
      {...props}
      smooth
      css={css`
        width: max-content;
        padding: 7px 20px;
        background-image: ${active
          ? "linear-gradient(to top, #f67f28, #eb5739)"
          : "unset"};
        background-color: unset;
        border-radius: 0 15px 0 15px;
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
        font-weight: bold;
        -webkit-transition: background-image 0.5s;
        transition: background-image 0.5s;
        &:hover {
          background-image: ${active
            ? "linear-gradient(to bottom, #f67f28, #eb5739)"
            : "unset"};
          color: ${active ? "#e0e0e0" : "white"};
        }
      `}
    >
      {children}
    </HashLink>
  );
};
