import React from "react";
import Home from "../pages/Home/Home";
import AllTenders from "../pages/AllTenders/AllTenders";
import AllConsultancies from "../pages/AllConsultancies/AllConsultancies";
import ConsultanciesAnswer from "../pages/ConsultanciesAnswer/ConsultanciesAnswer";
import LoginForm from "../pages/LoginForm/LoginForm";
import RegisterForm from "../pages/RegisterForm/RegisterForm";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import AddSingleTenders from "../pages/AddSingleTenders/AddSingleTenders";
import AddSingleConsultancy from "../pages/AddSingleConsultancy/AddSingleConsultancy";
import TendersDetails from "../pages/TendersDetails/TendersDetails";
import UserInformations from "../pages/UserInformations/UserInformations";
// import heroSecBackground from "../assets/GetProfitBaner.jpg";
// import heroSecBackground2 from "../assets/background2.png";

const pages = [
  {
    path: "/consultancies/:id",
    component: <ConsultanciesAnswer />,
  },

  // User Data Forms
  {
    path: "/login",
    component: <LoginForm />,
  },
  {
    path: "/Register",
    component: <RegisterForm />,
  },
  {
    path: "/ForgotPassword",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-confirm/:id/:token",
    component: <ChangePassword />,
  },
  //////////////////////////

  // Add Tenders and Consultancy Pages

  {
    path: "/AddSingleTenders",
    component: <AddSingleTenders />,
  },

  {
    path: "/AddSingleConsultancy",
    component: <AddSingleConsultancy />,
  },

  // Consultancies Answer Page
  {
    path: "/consultancies/:id",
    component: <ConsultanciesAnswer />,
  },

  // Tenders Details Page
  {
    path: "/tenders/:id",
    component: <TendersDetails />,
  },

  // Add Tenders and Add Consult
  {
    path: "/AddSingleTenders",
    component: <AddSingleTenders />,
  },
  {
    path: "/AddSingleConsultancy",
    component: <AddSingleConsultancy />,
  },

  /////////////////

  // Navbar Links

  {
    name: {
      en: "home",
      ar: "الرئيسية",
    },
    path: "/",
    component: <Home />,
    hashLink: "/#home",
  },
  {
    name: {
      en: "tenders",
      ar: "المناقصات",
    },
    path: "/tenders",
    component: <AllTenders />,
    hashLink: "/#tenders",
  },
  {
    name: {
      en: "consultancies",
      ar: "الإستشارات",
    },
    path: "/consultancies",
    component: <AllConsultancies />,
    hashLink: "/#consultancies",
  },
  {
    name: {
      en: "about us",
      ar: "من نحن",
    },
    hashLink: "/#aboutUs",
  },
  {
    name: {
      en: "Subscription",
      ar: "الأشتراك",
    },
    hashLink: "/#Subscription",
  },
  {
    name: {
      en: "contact us",
      ar: "تواصل معنا",
    },
    hashLink: "/#contactUs",
  },

  {
    name: {
      en: "Partners",
      ar: "شركائنا",
    },
    hashLink: "/#partners",
  },

  ///////////////////////////////

  {
    path: "/UserInformations/:id",
    component: <UserInformations />,
  },
];

export default pages;
