//************************  React import   ************************ //
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../../components";
import ConsultancyCard from "../../../components/ConsultancyCard";

// Authentication
import pageHoc from "../../../features/accessibility/hoc/PageHoc";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import { Box, CircularProgress, Typography } from "@mui/material";
//************************ Constant Data import   ************************ //
import { CONSULTDEP, CONSULTS } from "../../../data/apis";
import { ALLACCESS } from "../../../data/semantics";

/////////////////////////////////////////////////////////////////////////////

const Consultancies = () => {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);

  // Get tenders Data from store
  const consultStore = useSelector(
    (state) => state.home.value.consultancies.results
  );

  //*************************************************************** //

  //************************  API Request   ************************ //

  // Get tenders Data from server
  const [request, data] = useFetch({ url: CONSULTS, method: "get" });

  //*************************************************************** //

  //************************  useEffect ************************ //
  useEffect(() => {
    request({
      onSuccess: (res) => {
        dispatch({ type: "home/setConsultancies", payload: res.data });
      },
    });
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  // ****  Navigate ****//
  // Navigate to consultancies page
  const handleSeeAll = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/consultancies");
  };

  // Navigate to specific consult page
  const handleClick = (e, consultancy) => {
    navigate(`/consultancies/${consultancy.id}`);
  };
  //*************//
  //*************************************************************** //

  return (
    <Box
      id="consultancies"
      className="global-padding-y"
      sx={{
        backgroundImage: "linear-gradient(136deg, #EF543599, #F78A24B3)",
      }}
    >
      <Wrapper>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Heading>{global.Consultancies[lang]}</Heading>

            {data.isPending ? (
              <CircularProgress />
            ) : consultStore ? (
              consultStore?.map((consultancy, index) => {
                if (index < 3)
                  return (
                    <ConsultancyCard
                      key={`consultancy ${consultancy.id}`}
                      title={consultancy.title}
                      
                      status={consultancy?.status}
                      autoClamp
                      onClick={(e) => handleClick(e, consultancy)}
                    >
                      {consultancy.description}
                    </ConsultancyCard>
                  );
              })
            ) : (
              <Typography sx={{ fontSize: "2rem", color: "red" }}>
                {global.consultNotFound[lang]}
              </Typography>
            )}

            <Button
              variant="md"
              sx={{ marginTop: 5, width: 160 }}
              onClick={handleSeeAll}
            >
              {global.showAll[lang]}
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default pageHoc(Consultancies, ALLACCESS);
