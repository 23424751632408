//************************  React import   ************************ //
import React from "react";

//************************ Custom Component import   ************************ //
import Button from "./Button";

//************************  Matrial UI import   ************************ //
import { Box, Skeleton, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

/////////////////////////////////////////////////////////////////////////////

const ConsultancyCard = ({
  sx = {},
  title,
  status,
  children,
  image,
  buttonLabel = "التفاصيل",
  hideButton = false,
  autoClamp = false,
  onClick,
}) => {
  let statusStyle = "#cc3300";
  switch (status) {
    case "completed":
      statusStyle = "#99cc33";
      break;
    case "pending":
      statusStyle = "#ffcc00";
      break;
    case "Waiting":
      statusStyle = "#cc3300";
      break;
    default:
      statusStyle = "#cc3300";
      break;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        bgcolor: "white",
        p: 2,
        boxSizing: "border-box",
        width: "100%",
        border: "2px solid rgba(247, 138, 36 , 0.8)",
        boxShadow:
          "inset 1px 1px 18px rgba(239, 84, 53,0.2) , 1px 1px 18px rgba(239, 84, 53,0.2) ",
        borderRadius: "0 40px 0 40px",
        gap: 5,
        marginBlock: "1.5rem",
        ...sx,
      }}
    >
      <Box sx={{ maxWidth: "80%" }} className="consultancy-card__right-wrapper">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            className="consultancy-card__title"
            sx={{
              fontWeight: "bold",
              width: "100vmax",
              maxWidth: "100%",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          className="consultancy-card__content"
          sx={{
            display: "-webkit-box",
            width: "100%",
            WebkitLineClamp: !autoClamp ? "unset" : 10,
            WebkitBoxOrient: !autoClamp ? "initial" : "vertical",
            overflow: !autoClamp ? "unset" : "hidden",
            fontWeight: "bold",
            color: (theme) => theme.palette.neutral.main,
          }}
        >
          {children}
        </Typography>
      </Box>
      {!hideButton && (
        <Box
          className="consultancy-card__left-wrapper"
          sx={{
            maxWidth: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 25,
                borderRadius: "0 10px 0 10px",
                textAlign: "center",
                marginBottom: "1rem",
                bgcolor: statusStyle,
                color: "#fff",
                fontWeight: "bold",
                paddingInline: "0.5rem",
              }}
              className="consultancy-card__details-button"
            >
              {status}
            </Box>
            <Button
              onClick={(e) => onClick(e)}
              sx={{ width: 150, fontSize: "1.5rem" }}
              className="consultancy-card__details-button"
            >
              {buttonLabel}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConsultancyCard;

// text-overflow: ellipsis;
// overflow: hidden;
// width: 160px;
// height: 1.2em;
// white-space: nowrap;

// width: {
//   xs: 150,
//   sm: 300,
//   md: 500,
//   lg: 850,
//   xl: 850,
// },
