import React from "react";

function Logo({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="57.987"
      height="60.539"
      viewBox="0 0 57.987 60.539"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ef3d39"></stop>
          <stop offset="1" stopColor="#f89920"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0"
          x2="1"
          y1="0.501"
          y2="0.501"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="0"
          x2="1"
          y1="0.502"
          y2="0.502"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
      </defs>
      <g data-name="بيت المشورة-01" transform="translate(-262.524 -126.83)">
        <g data-name="Group 2" transform="translate(262.524 126.83)">
          <path
            fill="url(#linear-gradient)"
            d="M386.98 279.441a17.746 17.746 0 0017.345 0l-4.03-4.2a3.4 3.4 0 00-1.429-.774.347.347 0 01-.079-.639 4.107 4.107 0 01.891-.351 4.779 4.779 0 011.349-.141c.589.015 1.781-.091 1.781-1.191v-1.46l-.373-.051a.4.4 0 01-.348-.4v-.545a.584.584 0 01.584-.584h.525v-1.557h.844a.822.822 0 00.659-.309.4.4 0 00.093-.236 13.679 13.679 0 00-1.759-2.218.965.965 0 01-.247-.646v-2.011a5.137 5.137 0 00-5.333-5.229 6.369 6.369 0 00-2.947.639 6.305 6.305 0 00-3.457 5.69v.692a6.649 6.649 0 00.337 2.1 6.393 6.393 0 01.384 1.761c-.013.569-.009 1.966 0 2.837a4.225 4.225 0 01-.265 1.5c-.693 1.834-2.583 6.468-4.525 7.323z"
            data-name="Path 1"
            transform="translate(-364.202 -233.09)"
          ></path>
          <path
            fill="url(#linear-gradient-2)"
            d="M489.913 339.09q1.2.8 2.388 1.616c.4.269.8.531 1.184.82s.761.586 1.118.913l-.013.016c-.426-.231-.825-.5-1.228-.761s-.776-.565-1.16-.855q-1.156-.859-2.3-1.733z"
            data-name="Path 2"
            transform="translate(-448.286 -300.243)"
          ></path>
          <path
            fill="url(#linear-gradient-3)"
            d="M491.837 334.3q1.18.431 2.354.877c.392.146.789.284 1.171.452s.761.35 1.127.558l-.007.02a12.352 12.352 0 01-1.2-.386c-.393-.145-.772-.324-1.155-.49q-1.153-.5-2.3-1.012z"
            data-name="Path 3"
            transform="translate(-449.863 -296.329)"
          ></path>
          <path
            fill="url(#linear-gradient-4)"
            d="M493.442 329.61q1.153.082 2.3.181c.384.031.769.049 1.151.106a10.679 10.679 0 011.138.22v.02a10.612 10.612 0 01-1.159-.033c-.384-.029-.765-.093-1.146-.146q-1.145-.154-2.29-.326z"
            data-name="Path 4"
            transform="translate(-451.178 -292.498)"
          ></path>
          <path
            fill="url(#linear-gradient-5)"
            d="M494.19 323.223q1.208-.044 2.414-.073c.4-.011.805-.035 1.206-.02a11.587 11.587 0 011.206.093v.02a11.665 11.665 0 01-1.206.093c-.4.013-.805-.009-1.206-.02q-1.208-.027-2.414-.073z"
            data-name="Path 5"
            transform="translate(-451.791 -287.199)"
          ></path>
          <path
            fill="url(#linear-gradient-6)"
            d="M493.44 314.424q1.438-.288 2.879-.558c.48-.091.957-.2 1.442-.264s.97-.128 1.461-.152v.02c-.472.139-.952.236-1.429.335s-.965.152-1.45.223q-1.449.217-2.9.415z"
            data-name="Path 6"
            transform="translate(-451.178 -279.295)"
          ></path>
          <path
            fill="#515251"
            d="M291.546 195.394a4.206 4.206 0 00.11.972v.168L273.8 214.4v19c0 3.854-5.139 3.5-5.139 3.5-6.365.586-6.133-3.5-6.133-3.5v-25.116l18.312-18.594 1.927-1.991c6.53-.952 8.668 2.29 10.02 3.324a1.365 1.365 0 00.159.54l.037.066.029.051a2.107 2.107 0 00.187.284.176.176 0 01-.044.249 3.859 3.859 0 00-1.609 3.181z"
            data-name="Path 7"
            transform="translate(-262.524 -176.417)"
          ></path>
          <path
            fill="url(#linear-gradient-7)"
            d="M381.432 156.715a1.365 1.365 0 00.159.54l.037.066a64.319 64.319 0 00-9.4-1.623 1.366 1.366 0 01-.814-2.306l6.389-6.6a3.472 3.472 0 012.588-1.254l.027.234v.033c.415 3.115.663 6.34.922 9.753l.088 1.155z"
            data-name="Path 8"
            transform="translate(-351.169 -142.11)"
          ></path>
          <path
            fill="url(#linear-gradient-8)"
            d="M476.35 171.047a.267.267 0 01-.017.4c-7.851 6.241-13.269 1.052-13.269 1.052l-7.881-7.072a.272.272 0 01-.088-.185 3.83 3.83 0 00-1.607-2.926.263.263 0 01-.066-.364 1.21 1.21 0 00.042-.066 1.99 1.99 0 00.3-.9l.106-1.29c.243-2.97.474-5.794.785-8.541a.268.268 0 01.45-.163z"
            data-name="Path 9"
            transform="translate(-418.448 -146.515)"
          ></path>
          <g data-name="Group 1" transform="translate(30.955)">
            <path
              fill="#515251"
              d="M433.514 126.83c1.265 0 1.814.317 1.814.992 0 .507-.106 1.18-.253 2.447-.421 3.479-.7 7.043-1.012 10.882-.148.232-1.012.232-1.138 0-.295-3.838-.549-7.4-1.012-10.882-.148-1.265-.253-1.94-.253-2.447 0-.675.569-.992 1.814-.992zm-.042 20.877a1.764 1.764 0 01-1.772-1.9 1.808 1.808 0 011.814-1.9 1.833 1.833 0 011.876 1.9 1.8 1.8 0 01-1.876 1.9z"
              data-name="Path 10"
              transform="translate(-431.66 -126.83)"
            ></path>
          </g>
        </g>
        <g
          fill="#515251"
          data-name="Group 3"
          transform="translate(278.702 179.153)"
        >
          <path
            d="M354.1 420.4a1.543 1.543 0 01-.432 1.144 1.56 1.56 0 01-1.151.432 1.62 1.62 0 01-1.164-.425 1.534 1.534 0 01-.432-1.144 1.559 1.559 0 01.425-1.144 1.536 1.536 0 011.138-.425 1.619 1.619 0 011.17.419 1.473 1.473 0 01.446 1.143zm-1.817-2.072h-.811v-.752h.811zm.943 2.1c0-.6-.242-.9-.732-.9s-.712.3-.712.9a.95.95 0 00.176.595.624.624 0 00.536.249.655.655 0 00.556-.249.95.95 0 00.174-.586zm.293-2.1h-.811v-.752h.811z"
            data-name="Path 11"
            transform="translate(-350.92 -416.691)"
          ></path>
          <path
            d="M373.115 427.8a1.639 1.639 0 01-.458 1.3 2.014 2.014 0 01-1.367.379v-.739q.9 0 .9-.862v-2.438h.922z"
            data-name="Path 12"
            transform="translate(-367.562 -423.112)"
          ></path>
          <path
            d="M388.413 428.369h-.862q-.156 1.079-1.49 1.078h-.1v-.732h.079c.353 0 .562-.13.635-.386a2.3 2.3 0 01-.562.038 1.437 1.437 0 01-1.484-1.49 1.412 1.412 0 011.484-1.477 1.428 1.428 0 011.451 1.5v.595h.849v.877zm-1.766-.877v-.635a.566.566 0 00-.562-.633c-.346 0-.529.267-.529.615a.587.587 0 00.562.653z"
            data-name="Path 13"
            transform="translate(-378.46 -423.079)"
          ></path>
          <path
            d="M410.375 418.009h-6.865v-.877h.824c.3 0 .465-.163.465-.62v-1.464h.922v1.426a1.887 1.887 0 01-.112.661h.582c.307 0 .465-.2.465-.589v-1.5h.915v1.426a1.887 1.887 0 01-.112.661h.582c.307 0 .465-.2.465-.589v-1.5h.922v1.418a1.819 1.819 0 01-.117.666h1.065v.877zm-3.5-3.459h-.816v-.75h.816zm.6-1.078h-.811v-.752h.811zm.628 1.078h-.811v-.75h.811z"
            data-name="Path 14"
            transform="translate(-393.885 -412.72)"
          ></path>
          <path
            d="M444.451 430.877h-1.045a1.412 1.412 0 01-1.223.562 1.445 1.445 0 01-1.243-.562h-1V430h.732a1.385 1.385 0 011.484-1.53 1.406 1.406 0 011.53 1.53h.765zm-1.673-.895c0-.516-.2-.778-.582-.778h-.066c-.333 0-.556.313-.556.739s.221.752.608.752.597-.276.597-.713z"
            data-name="Path 15"
            transform="translate(-423.648 -425.587)"
          ></path>
          <path
            d="M464.792 421.558a1.322 1.322 0 01-.287.864 1.093 1.093 0 01-.9.4h-.862v-.877h.674c.307 0 .465-.216.465-.641v-2.694h.915v2.948z"
            data-name="Path 16"
            transform="translate(-442.275 -417.532)"
          ></path>
          <path
            d="M478.505 422.8h-.915v-4.22h.915z"
            data-name="Path 17"
            transform="translate(-454.407 -417.508)"
          ></path>
          <path
            d="M499.309 425.019h-4.445c-1.444 0-2.163-.608-2.163-1.83v-1.137h.9v1.137a.835.835 0 00.366.791 1.882 1.882 0 00.915.163h2.085c.307 0 .465-.209.465-.628v-1.459h.915v1.426a1.963 1.963 0 01-.1.661h1.065zm-3.955-2.961h-.816v-.758h.816zm1.235 0h-.816v-.758h.816z"
            data-name="Path 18"
            transform="translate(-466.752 -419.73)"
          ></path>
          <path
            d="M528.231 429.478h-.811v-.758h.811zm2.28-1.085h-2.941v-.877h.6c.307 0 .458-.209.458-.628v-1.458h.922v1.426a1.963 1.963 0 01-.1.661h1.065v.877zm-1.052 1.085h-.811v-.758h.811z"
            data-name="Path 19"
            transform="translate(-495.117 -423.104)"
          ></path>
          <path
            d="M545.57 427.138a1.321 1.321 0 01-.287.864 1.089 1.089 0 01-.895.4h-.778v-.877h.582c.307 0 .465-.216.465-.641v-1.444h.915v1.7zm-.053 2.348h-.811v-.758h.811z"
            data-name="Path 20"
            transform="translate(-508.344 -423.112)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
