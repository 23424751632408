//************************  React import   ************************ //
import React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../components";

//************************  Matrial UI import   ************************ //
import {
  Box,
  Button,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

/////////////////////////////////////////////////////////////////////////////

const Topbar = () => {
  //************************  Hooks   ************************ //
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const loginLang = useSelector((state) => state.lang.value.login);
  const logoutLang = useSelector((state) => state.lang.value.logout);

  // get token from userInfo store
  const { token } = useSelector((state) => state.userInfo.value);

  // Get username from userInfo
  const { username, id, role } = useSelector((state) => state.userInfo.value);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // Toggle lang from Arabic to English or vice versa
  const handleChange = (e) => {
    dispatch({ type: "lang/toggleLang", payload: e.target.value });
  };

  // Logout handler
  const handelLogout = () => {
    token ? dispatch({ type: "userInfo/remove" }) : "";

    window.scrollTo({
      top: 0,
    });
    navigate(`/login`);
  };

  // navigate to user Information page
  const handelUserInfoPage = () => {
    navigate(`/UserInformations/${id}`);
  };

  //*************************************************************** //

  return (
    <Box
      sx={{
        bgcolor: "#525252",
        color: "white",
        position: "absolute",
        width: "100%",
      }}
    >
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            gap: 2,
            height: 35,
          }}
        >
          <Typography
            onClick={handelLogout}
            sx={{
              cursor: "pointer",
              color: "#EF5435",
              fontWeight: "bold",
              fontSize: {
                xs: "0.8rem",
                sm: "0.8rem",
                md: "1rem",
                lg: "1.8rem",
                xl: "1.8rem",
              },
            }}
          >
            {token ? logoutLang[lang] : loginLang[lang]}
          </Typography>
          <Typography
            sx={{
              color: "#EF5435",
              fontSize: {
                xs: "0.8rem",
                sm: "0.8rem",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              fontWeight: "bold",
            }}
          >
            {token && role}
          </Typography>
          {token && (
            <Box
              onClick={handelUserInfoPage}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <PersonIcon />
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                    sm: "0.8rem",
                    md: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                }}
              >
                {token != "" ? username : username[lang]}
              </Typography>
            </Box>
          )}

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            label="Age"
            MenuProps={{
              disableScrollLock: true,
            }}
            onChange={handleChange}
            sx={{
              height: "100%",
              color: "#fff",
              width: "fit-content",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            <MenuItem value={"ar"}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  عربي
                </Typography>
                <Box
                  component={"img"}
                  src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
                  sx={{ width: "20px" }}
                />
              </Box>
            </MenuItem>
            <MenuItem value={"en"}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  English
                </Typography>
                <Box
                  component={"img"}
                  src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
                  sx={{ width: "20px" }}
                />
              </Box>
            </MenuItem>
          </Select>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Topbar;
