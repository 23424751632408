import { configureStore } from "@reduxjs/toolkit";
import LangSlice from "./store/LangSlice";
import ConsultanciesSlice from "./store/ConsultanciesSlice";
import ConsultanciesDepSlice from "./store/ConsultanciesDepSlice";
import TendersSlice from "./store/TendersSlice";
import UserInfoSlice from "./store/UserInfoSlice";
import TenderActivitySlice from "./store/TenderActivitySlice";
import OfficesSlice from "./store/OfficesSlice";
import HomeSlice from "./store/HomeSlice";

export default configureStore({
  reducer: {
    home: HomeSlice,
    lang: LangSlice,
    userInfo: UserInfoSlice,
    consultancies: ConsultanciesSlice,
    consultanciesDep: ConsultanciesDepSlice,
    tenders: TendersSlice,
    tenderactivity: TenderActivitySlice,
    offices: OfficesSlice,
  },
});
