//************************  React import   ************************ //
import React, { Fragment, useState } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../components";
import FormTable from "../../components/FormTable";

import authHoc from "../../features/accessibility/hoc/AuthHoc";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";

//************************ Constant Data import   ************************ //
import { PASSWORDRESET } from "../../data/apis";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/Betngan.png";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

///////////////////////////////////////////////////////////////////////////////////////////

const ForgotPassword = () => {
  //************************  Hooks   ************************ //

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const forgotPassowrdLang = useSelector(
    (state) => state.lang.value.forgotPassowrd
  );
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Send data to server and save response in redux
  const [request, data] = useFetch({
    url: PASSWORDRESET,
    method: "post",
    successMessage: "تم",
  });

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // Validation Schema using Yup
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
  } = useFormik({
    initialValues: {
      email: "",
      host: document.location.host,
    },

    validationSchema: forgotPasswordSchema,
    onSubmit: () => {
      request({
        body: values,
        onSuccess: (res) => {
          handleReset();
        },
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });

  //*************************************************************** //

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Wrapper>
          <FormTable
            onSubmit={handleSubmit}
            fromHeader={forgotPassowrdLang[lang]}
            submitButtonLabel={global.send[lang]}
          >
            {data.isPending ? (
              <CircularProgress color="secondary" />
            ) : (
              <TextField
                id="email"
                label={global.email[lang]}
                variant="standard"
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{
                  "& input": {
                    fontSize: "1.5rem",
                    color: "#fff",
                  },
                  "	.MuiInputLabel-root": {
                    color: "#fff",
                  },
                  ".MuiInputLabel-root.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />
            )}
          </FormTable>
        </Wrapper>
      </Box>
      {data.successAlert}
      {data.failAlert}
    </Fragment>
  );
};

export default authHoc(ForgotPassword);
