import { createSlice } from "@reduxjs/toolkit";

export const HomeSlice = createSlice({
  name: "home",
  initialState: {
    value: {
      tenders: [],
      consultancies: [],
    },
  },
  reducers: {
    setTender: (state, action) => {
      state.value.tenders = action.payload;
    },
    setConsultancies: (state, action) => {
      state.value.consultancies = action.payload;
    },

    pushTender: (state, action) => {
      state.value.tenders = [...state.value, action.payload];
    },
    pushConsultancies: (state, action) => {
      state.value.consultancies = [...state.value, action.payload];
    },
  },
});

export default HomeSlice.reducer;
