//************************  React import   ************************ //
import React from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Wrapper } from "../../../components";

//************************  Matrial UI import   ************************ //
import { Box, Stack, Typography } from "@mui/material";
////////////////////////////////////////////////////////////////////////////////////////////

const AddConsultancy = () => {
  //************************  Hooks   ************************ //
  const navigate = useNavigate();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const addConsultancieLang = useSelector(
    (state) => state.lang.value.home.addConsultancie
  );
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // ****  Navigate ****//
  // Navigate to Consultancies page
  const handleAddConsultancy = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/AddSingleConsultancy", { state: { name: "" } });
  };

  //*************//
  //*************************************************************** //

  return (
    <Box className="global-padding-y " sx={{ backgroundColor: "#525252" }}>
      <Wrapper>
        <Stack
          spacing={6}
          className="global-padding-y"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: "3rem", color: "#fff", fontWeight: "bold" }}
          >
            {addConsultancieLang.header[lang]}
            <Box sx={{ display: "inline", color: "#EF5435" }}>
              {addConsultancieLang.mashora[lang]}
            </Box>
          </Typography>
          <Button
            variant="lg"
            sx={{ width: "25rem" }}
            onClick={handleAddConsultancy}
          >
            {global.addConsultancie[lang]}
          </Button>
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default AddConsultancy;
