//************************  React import   ************************ //
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../components";
import ConsultancyCard from "../../components/ConsultancyCard";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";
import pageHoc from "../../features/accessibility/hoc/PageHoc";

//************************  Matrial UI import   ************************ //
import { Box, CircularProgress, Typography } from "@mui/material";

//************************ Constant Data import   ************************ //
import { CONSULTS } from "../../data/apis";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/GetProfitBaner.jpg";
import { ALLACCESS } from "../../data/semantics";

///////////////////////////////////////////////////////////////////////////////////////////

const AllConsultancies = () => {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();
  // Navigate
  const navigate = useNavigate();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);

  // Get userinformation
  const userInfoStore = useSelector((state) => state.userInfo.value);
  // Get consultancie data
  const consultanciesStore = useSelector((state) => state.consultancies.value);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get consalts request
  const [request, data] = useFetch({
    url: CONSULTS,
    method: "get",
  });

  //*************************************************************** //

  //************************  useEffect ************************ //

  // Fire get consultancies request
  const getConsultance = () => {
    request({
      onSuccess: (res) => {
        dispatch({ type: "consultancies/set", payload: res.data });
      },
    });
  };

  useEffect(() => {
    getConsultance();
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  // Navigate to specific consultancies page
  const handleClick = (e, consultancy) => {
    navigate(`/consultancies/${consultancy.id}`);
  };

  console.log(consultanciesStore.results);

  //*************************************************************** //

  return (
    <Box
      className="global-padding-y"
      sx={{
        paddingTop: 25,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "calc(15vh)",
          backgroundImage: `url("${backgroundImage}")`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></Box>
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Heading>{global.Consultancies[lang]}</Heading>

          {consultanciesStore.results?.length > 0 ? (
            consultanciesStore.results?.map((consultancy, index) => (
              <ConsultancyCard
                key={`consultancy ${consultancy.id}`}
                title={consultancy?.title}
                status={consultancy?.status}
                onClick={(e) => handleClick(e, consultancy)}
              >
                {consultancy?.description}
              </ConsultancyCard>
            ))
          ) : data.isPending ? (
            ""
          ) : (
            <Typography sx={{ color: "red", fontSize: "5rem" }}>
              {global.consultNotFound[lang]}
            </Typography>
          )}

          {data.isPending ? (
            <CircularProgress />
          ) : (
            consultanciesStore.next && (
              <Button
                sx={{ fontSize: "2rem", paddingInline: 5 }}
                onClick={getConsultance}
              >
                المزيد
              </Button>
            )
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};

export default pageHoc(AllConsultancies, ALLACCESS);
