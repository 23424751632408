//************************ Redux import   ************************ //
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//************************ Custom Component import   ************************ //
import { Button, Wrapper } from "../../../components";
import Heading from "../../../components/Heading";

//************************  Matrial UI import   ************************ //
import { Box, Stack, Typography } from "@mui/material";

//************************  Assets import   ************************ //

//************************  Library import   ************************ //

/////////////////////////////////////////////////////////////////////////////

const AddTenders = () => {
  //

  //************************  Hooks   ************************ //
  const navigate = useNavigate();

  //*************************************************************** //
  // Get lang from redux
  const lang = useSelector((state) => state.lang.value.lang);
  const tenderAddLang = useSelector((state) => state.lang.value.home.addTender);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  const handleAddTender = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/AddSingleTenders");
  };
  //*************************************************************** //

  /////////////////////////////////////////////////////////////////////////////////////////

  return (
    <Box className="global-padding-y " sx={{ backgroundColor: "#525252" }}>
      <Wrapper>
        <Stack
          spacing={6}
          className="global-padding-y"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "#525252" }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: "3rem", color: "#fff", fontWeight: "bold" }}
          >
            {tenderAddLang.header[lang]}
            <Box sx={{ display: "inline", color: "#EF5435" }}>
              {tenderAddLang.mashora[lang]}
            </Box>
          </Typography>
          <Button
            variant="lg"
            sx={{ width: "25rem" }}
            onClick={handleAddTender}
          >
            {global.addTender[lang]}
          </Button>
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default AddTenders;
