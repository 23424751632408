import { createTheme } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
// import { lang } from "./store/LangSlice";
import { prefixer } from "stylis";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF5435",
    },
    secondary: {
      main: "#F78A24",
    },
    neutral: {
      main: "#525252",
    },
  },
  typography: {
    fontFamily: "'Tajawal', sans-serif",
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root.Mui-focused": {
            color: "black",
          },
        },
        underline: {
          "&::before": {
            borderBottom: "1px solid #EF5435",
          },
          "&:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #EF5435",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: "max-content",
          p: "7px 20px",
          backgroundImage: "linear-gradient(to top, #f67f28, #eb5739)",
          backgroundColor: "unset",
          boxShadow: "none",
          borderRadius: "0 15px 0 15px",
          fontWeight: "bold",
          "&:hover": {
            backgroundImage: "linear-gradient(to bottom, #f67f28, #eb5739)",
            color: "#e0e0e0",
          },
        },
      },
    },
  },
});

export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export const cacheLtr = createCache({
  key: "muiltr",
  stylisPlugins: [],
});

export default theme;
