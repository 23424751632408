//************************  React import   ************************ //
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../components";
import ConsultancyCard from "../../components/ConsultancyCard";
import Inputs from "../../components/Inputs";

import pageHoc from "../../features/accessibility/hoc/PageHoc";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import { Box, Stack } from "@mui/system";
import { TextField } from "@mui/material";

//************************ Constant Data import   ************************ //
import { CONSULTANCIES, CONSULTS } from "../../data/apis";
import { ALLACCESS } from "../../data/semantics";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/GetProfitBaner.jpg";

//************************  Library import   ************************ //
// Form Validation
import * as Yup from "yup";
import { useFormik } from "formik";

///////////////////////////////////////////////////////////////////////////////////////////

const ConsultanciesAnswer = () => {
  //************************  Hooks   ************************ //
  // save consalte data
  const [reqData, setReqData] = useState({
    answer: "",
    date: "",
    title: "",
  });

  // Navigate
  const navigate = useNavigate();

  // Take id from url
  const { id } = useParams();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);
  // Get user information data from store
  const userRole = useSelector((state) => state.userInfo.value.role);

  //*************************************************************** //

  //************************  API Request   ************************ //

  // Get CONSULTANCIES data from server
  const [request, data] = useFetch({ url: CONSULTANCIES, method: "get" });

  // send consult answer to server
  const [requestAnswer, dataAnswer] = useFetch({
    url: CONSULTS,
    method: "patch",
  });

  //*************************************************************** //

  //************************  useEffect ************************ //
  // Fire get consult request
  const getconsaltAnswer = () => {
    request({
      aditions: [`${id}`],
      onSuccess: (res) => {
        setReqData(res.data);
        setFieldValue("Answer", res.data.Answer);
      },
    });
  };
  useEffect(() => {
    getconsaltAnswer();
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // Navigate to home page
  const handelHome = () => {
    setTimeout(() => {
      navigate("/home");
    }, [1500]);
  };

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // Validation Schema using Yup
  const answerSchema = Yup.object().shape({
    answer: Yup.string(),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: {
      Answer: "",
    },
    validationSchema: answerSchema,
    onSubmit: () => {
      requestAnswer({
        body: values,
        aditions: [`${id}`],
        onSuccess: () => {
          handelHome();
        },
      });
    },
  });

  //*************************************************************** //

  return (
    <Box className="global-padding-y" sx={{ paddingTop: 25 }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "calc(15vh)",
          backgroundImage: `url("${backgroundImage}")`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></Box>
      <Wrapper>
        <Stack spacing={3} alignItems={"center"}>
          <ConsultancyCard hideButton title={reqData.title} date={reqData.date}>
            {reqData.description}
          </ConsultancyCard>
          <Heading>{global.answer[lang]}</Heading>

          <Stack
            component={"form"}
            sx={{ width: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
            onSubmit={handleSubmit}
          >
            <ConsultancyCard hideButton>
              <Inputs
                id={"Answer"}
                variant={"standard"}
                name={"Answer"}
                placeholder={
                  userRole === "client"
                    ? values.Answer
                      ? values.Answer
                      : global.answerForClint[lang]
                    : values.Answer
                    ? values.Answer
                    : global.answerForOffice[lang]
                }
                multiline={true}
                rows={5}
                type={"textarea"}
                onChange={handleChange}
                value={values.Answer}
                onBlur={handleBlur}
                width={"100%"}
                answerTextColor={"#000"}
                disabled={userRole === "client" ? true : false}
              />
            </ConsultancyCard>
            {userRole === "client" ? (
              ""
            ) : (
              <Button
                type="submit"
                sx={{
                  width: {
                    xs: "20%",
                    sm: "20%",
                    md: "20%",
                    lg: "15%",
                    xl: "15%",
                  },
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                  color: "#fff",
                }}
              >
                {global.send[lang]}
              </Button>
            )}
          </Stack>
        </Stack>
        {dataAnswer.successAlert}
        {dataAnswer.failAlert}
      </Wrapper>
    </Box>
  );
};

export default pageHoc(ConsultanciesAnswer, ALLACCESS);
