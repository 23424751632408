//************************  React import   ************************ //
import React from "react";
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Wrapper } from "../../../components";
import Heading from "../../../components/Heading";

//************************  Matrial UI import   ************************ //
import { Box, Stack, Typography } from "@mui/material";

//////////////////////////////////////////////////////////////////////////////////
const AboutUs = () => {
  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const aboutUsLang = useSelector((state) => state.lang.value.home.aboutUs);

  //*************************************************************** //

  return (
    <Box
      id="aboutUs"
      className="global-padding-y"
      sx={{
        textAlign: "center",
        minHeight: "50rem",
        backgroundImage: "linear-gradient(136deg, #EF543599, #F78A24B3)",
      }}
    >
      <Wrapper>
        <Stack
          spacing={6}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ color: "#fff" }}
        >
          <Heading>{aboutUsLang.header[lang]}</Heading>
          <Stack spacing={3} sx={{ maxWidth: "120rem" }}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {aboutUsLang.subHeader[lang]}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {aboutUsLang.paragraphOne[lang]}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {aboutUsLang.paragraphTwo[lang]}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {aboutUsLang.paragraphThree[lang]}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {aboutUsLang.paragraphFour[lang]}
            </Typography>
          </Stack>
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default AboutUs;
