//************************  React import   ************************ //
import React from "react";

//************************ Redux import   ************************ //
import { Typography } from "@mui/material";

/////////////////////////////////////////////////////////////////////////////

const Heading = ({ children, sx = {} }) => {
  return (
    <Typography
      variant="h3"
      sx={{
        fontWeight: "bold",
        color: "white",
        p: "7px 20px",
        backgroundImage: "linear-gradient(to top, #f67f28, #eb5739)",
        borderRadius: "0 15px 0 15px",
        width: "max-content",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default Heading;
