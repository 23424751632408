//************************  React import   ************************ //
import React from "react";

//************************  Components import   ************************ //
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import AddConsultancy from "./components/AddConsultancy";
import AddTenders from "./components/AddTenders";
import Partners from "./components/Partners";
import Tenders from "./components/Tenders";
import Consultancies from "./components/Consultancies";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

//************************ Custom Component import   ************************ //
import AccessGate from "../../features/accessibility/components/AccessGate";

//************************  Matrial UI import   ************************ //
import { Box } from "@mui/system";

//************************ Constant Data import   ************************ //
import { ADMIN, ALLACCESS, LOGOUT, USER } from "../../data/semantics";
import Sub from "./components/Sub";

const Home = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Hero />
      <Tenders />
      <AddTenders />
      <AccessGate roles={ALLACCESS}>
        <Consultancies />
        <AccessGate roles={[USER, ADMIN]}>
          <AddConsultancy />
        </AccessGate>
      </AccessGate>
      <Sub />
      <Contact />
      <Partners />
      <AboutUs />
      <Footer />
    </Box>
  );
};

export default Home;
