import { createSlice } from "@reduxjs/toolkit";

export const TendersSlice = createSlice({
  name: "tenders",
  initialState: {
    value: {
      count: 0,
      current: 0,
      next: "",
      page_size: 0,
      previous: "",
      results: [],
      total: 0,
    },
  },
  reducers: {
    set: (state, action) => {
      state.value = {
        ...action.payload,
        results: [...state.value.results, ...action.payload.results],
      };
    },
    push: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

export default TendersSlice.reducer;
