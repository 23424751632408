//************************  React import   ************************ //
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../components";
import DataTable from "../../components/DataTable";
import pageHoc from "../../features/accessibility/hoc/PageHoc";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import { Box } from "@mui/system";

//************************ Constant Data import   ************************ //
import { TENDERS } from "../../data/apis";
import { ALLACCESS } from "../../data/semantics";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/GetProfitBaner.jpg";

//************************  Library import   ************************ //
import { id } from "date-fns/locale";

///////////////////////////////////////////////////////////////////////////////////////////

const TendersDetails = () => {
  //************************  Hooks   ************************ //
  // Save tender details
  const [details, setDetails] = useState({});

  // Take id from url
  const param = useParams(id);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get tender details data from server
  const [request, data] = useFetch({ url: TENDERS, method: "get" });

  //*************************************************************** //

  //************************  useEffect ************************ //
  // fire get tender details
  const tenderDetails = () => {
    request({
      aditions: [param.id],
      onSuccess: (res) => {
        setDetails(res.data);
      },
    });
  };
  useEffect(() => {
    tenderDetails();
  }, []);

  return (
    <Box className="global-padding-y" sx={{ paddingTop: 25 }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "calc(15vh)",
          backgroundImage: `url("${backgroundImage}")`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></Box>
      <Wrapper>
        <DataTable data={details} />
      </Wrapper>
    </Box>
  );
};

export default pageHoc(TendersDetails, ALLACCESS, "/login");
